import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContextModalProps, modals} from "@mantine/modals";
import React from "react";

export interface Props {
  message?: string;
}

export function show(message: string) {
  modals.openContextModal({
    modal: "wait",
    centered: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps: {message},
  });
}

export default function Wait({innerProps}: ContextModalProps<Props>) {
  return (
    <>
      <FontAwesomeIcon icon={faSpinner} spin />
      <div>{innerProps.message || "Please wait..."}</div>
    </>
  );
}
