/**
 * This module was created to effectively vendor the apollo-upload-client package.
 * It also required vendoring the dependent packages: extract-files, is-plain-object
 *
 * The reason this was vendored was because the official versions of these packages don't play well with
 * the current module system and typescript:
 *
 * - apollo-upload-client requires nodenext modules, which requires changes in a ton of modules and introduces
 *   lots of breaking changes
 * - older versions of apollo-upload-client still don't work because the types for extract-files are broken
 *   and cause 'yarn tsc' to fail because it can't find type definitions
 *
 * To avoid a lot of headache, the code from these modules have been copied into this module and the JSDoc
 * types have been converted to proper TypeScript.
 */
import {ApolloLink} from "@apollo/client/link/core/ApolloLink";
import {createSignalIfSupported} from "@apollo/client/link/http/createSignalIfSupported";
import {parseAndCheckHttpResponse} from "@apollo/client/link/http/parseAndCheckHttpResponse";
import {rewriteURIForGET} from "@apollo/client/link/http/rewriteURIForGET";
import {Printer} from "@apollo/client/link/http/selectHttpOptionsAndBody";
import {
  defaultPrinter,
  fallbackHttpConfig,
  selectHttpOptionsAndBodyInternal,
} from "@apollo/client/link/http/selectHttpOptionsAndBody";
import {selectURI} from "@apollo/client/link/http/selectURI";
import {serializeFetchParameter} from "@apollo/client/link/http/serializeFetchParameter";
import {Observable} from "@apollo/client/utilities/observables/Observable";

export type ObjectPath = string;

/**
 * Deeply clonable value.
 */
type Cloneable =
  | Array<unknown>
  | FileList
  | {
      [key: PropertyKey]: unknown;
    };

/**
 * Clone of a {@link Cloneable deeply cloneable value}.
 */
type Clone = Exclude<Cloneable, FileList>;

export type ExtractableFile = File | Blob;

export interface Extraction<Extractable = ExtractableFile> {
  clone: unknown;
  files: Map<Extractable, ObjectPath[]>;
}

export function isExtractableFile(value: unknown): value is ExtractableFile {
  return (
    (typeof File !== "undefined" && value instanceof File) ||
    (typeof Blob !== "undefined" && value instanceof Blob)
  );
}

export function isPlainObject<Value>(
  value: unknown
): value is Record<PropertyKey, Value> {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return (
    (prototype === null ||
      prototype === Object.prototype ||
      Object.getPrototypeOf(prototype) === null) &&
    !(Symbol.toStringTag in value) &&
    !(Symbol.iterator in value)
  );
}

/**
 * Recursively extracts files and their {@link ObjectPath object paths} within a
 * value, replacing them with `null` in a deep clone without mutating the
 * original value.
 * [`FileList`](https://developer.mozilla.org/en-US/docs/Web/API/Filelist)
 * instances are treated as
 * [`File`](https://developer.mozilla.org/en-US/docs/Web/API/File) instance
 * arrays.
 * @template Extractable Extractable file type.
 * @param value Value to extract files from. Typically an object tree.
 * @param isExtractable Matches extractable files. Typically {@linkcode isExtractableFile}.
 * @param [path] Prefix for object paths for extracted files. Defaults to `""`.
 * @returns Extraction result.
 * @example
 * Extracting files from an object.
 *
 * For the following:
 *
 * ```js
 * import extractFiles from "extract-files/extractFiles.mjs";
 * import isExtractableFile from "extract-files/isExtractableFile.mjs";
 *
 * const file1 = new File(["1"], "1.txt", { type: "text/plain" });
 * const file2 = new File(["2"], "2.txt", { type: "text/plain" });
 * const value = {
 *   a: file1,
 *   b: [file1, file2],
 * };
 *
 * const { clone, files } = extractFiles(value, isExtractableFile, "prefix");
 * ```
 *
 * `value` remains the same.
 *
 * `clone` is:
 *
 * ```json
 * {
 *   "a": null,
 *   "b": [null, null]
 * }
 * ```
 *
 * `files` is a
 * [`Map`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map)
 * instance containing:
 *
 * | Key     | Value                        |
 * | :------ | :--------------------------- |
 * | `file1` | `["prefix.a", "prefix.b.0"]` |
 * | `file2` | `["prefix.b.1"]`             |
 */
export function extractFiles<Extractable>(
  value: unknown,
  isExtractable: (value: unknown) => value is Extractable,
  path: ObjectPath = "",
  ...args: any[]
): Extraction<Extractable> {
  if (!arguments.length) throw new TypeError("Argument 1 `value` is required.");

  if (typeof isExtractable !== "function")
    throw new TypeError("Argument 2 `isExtractable` must be a function.");

  if (typeof path !== "string")
    throw new TypeError("Argument 3 `path` must be a string.");

  /**
   * Map of values recursed within the input value and their clones, for reusing
   * clones of values that are referenced multiple times within the input value.
   */
  const clones: Map<Cloneable, Clone> = new Map();

  /**
   * Extracted files and their object paths within the input value.
   */
  const files: Extraction<Extractable>["files"] = new Map();

  /**
   * Recursively clones the value, extracting files.
   * @param {unknown} value Value to extract files from.
   * @param {ObjectPath} path Prefix for object paths for extracted files.
   * @param {Set<Cloneable>} recursed Recursed values for avoiding infinite
   *   recursion of circular references within the input value.
   * @returns {unknown} Clone of the value with files replaced with `null`.
   */
  function recurse(
    value: unknown,
    path: ObjectPath,
    recursed: Set<Cloneable> | null | undefined
  ): any {
    if (isExtractable(value)) {
      const filePaths = files.get(value);

      filePaths ? filePaths.push(path) : files.set(value, [path]);

      return null;
    }

    const valueIsList =
      Array.isArray(value) ||
      (typeof FileList !== "undefined" && value instanceof FileList);
    const valueIsPlainObject = isPlainObject(value);

    if (valueIsList || valueIsPlainObject) {
      let clone = clones.get(value);

      const uncloned = !clone;

      if (uncloned) {
        clone = valueIsList
          ? []
          : // Replicate if the plain object is an `Object` instance.
            value instanceof /** @type {any} */ Object
            ? {}
            : Object.create(null);

        if (clone !== undefined) {
          clones.set(value, clone);
        }
      }

      if (!recursed?.has(value)) {
        const pathPrefix = path ? `${path}.` : "";
        const recursedDeeper = new Set(recursed).add(value);

        if (valueIsList) {
          let index = 0;

          for (const item of value) {
            const itemClone = recurse(
              item,
              pathPrefix + index++,
              recursedDeeper
            );

            if (uncloned && clone !== undefined) {
              (clone as Array<unknown>).push(itemClone);
            }
          }
        } else
          for (const key in value) {
            const propertyClone = recurse(
              value[key],
              pathPrefix + key,
              recursedDeeper
            );

            if (uncloned && clone !== undefined) {
              /** @type {{ [key: PropertyKey]: unknown }} */
              clone[key] = propertyClone;
            }
          }
      }

      return clone;
    }

    return value;
  }

  return {
    clone: recurse(value, path, new Set()),
    files,
  };
}

/**
 * The default implementation for the function `createUploadLink` option
 * `formDataAppendFile` that uses the standard {@linkcode FormData.append}
 * method.
 * @param formData Form data to append the specified file to.
 * @param fieldName Field name for the file.
 * @param file File to append.
 */
export function formDataAppendFile(
  formData: FormData,
  fieldName: string,
  file: ExtractableFile
) {
  "name" in file
    ? formData.append(fieldName, file, file.name)
    : formData.append(fieldName, file);
}

export type CreateUploadLinkParams = {
  uri: string;
  useGETForQueries?: boolean;
  print?: Printer;
  fetch?: Function;
  fetchOptions?: RequestInit;
  credentials?: string;
  headers?: {[headerName: string]: string};
  includeExtensions?: boolean;
};

/**
 * Creates a
 * [terminating Apollo Link](https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link)
 * for [Apollo Client](https://www.apollographql.com/docs/react) that fetches a
 * [GraphQL multipart request](https://github.com/jaydenseric/graphql-multipart-request-spec)
 * if the GraphQL variables contain files (by default
 * [`FileList`](https://developer.mozilla.org/en-US/docs/Web/API/FileList),
 * [`File`](https://developer.mozilla.org/en-US/docs/Web/API/File), or
 * [`Blob`](https://developer.mozilla.org/en-US/docs/Web/API/Blob) instances),
 * or else fetches a regular
 * [GraphQL POST or GET request](https://www.apollographql.com/docs/apollo-server/workflow/requests)
 * (depending on the config and GraphQL operation).
 *
 * Some of the options are similar to the
 * [`createHttpLink` options](https://www.apollographql.com/docs/react/api/link/apollo-link-http/#httplink-constructor-options).
 * @see [GraphQL multipart request spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
 * @param options Options.
 * @param uri GraphQL endpoint URI. Defaults to `"/graphql"`.
 * @param [options.useGETForQueries] Should GET be used to fetch queries, if there are no files to upload.
 * @param [options.print] Prints the GraphQL query or mutation AST to a string for transport.
 *   Defaults to {@linkcode defaultPrinter}.
 * @param [options.fetch] [`fetch`](https://fetch.spec.whatwg.org) implementation. Defaults to the {@linkcode fetch} global.
 * @param {RequestInit} [options.fetchOptions] `fetch` options; overridden by upload requirements.
 * @param [options.credentials] Overrides {@linkcode RequestInit.credentials credentials} in {@linkcode fetchOptions}.
 * @param [options.headers] Merges with and
 *   overrides {@linkcode RequestInit.headers headers} in
 *   {@linkcode fetchOptions}.
 * @param [options.includeExtensions] Toggles sending `extensions` fields to the GraphQL server. Defaults to `false`.
 * @returns A [terminating Apollo Link](https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link).
 * @example
 * A basic Apollo Client setup:
 *
 * ```js
 * import { ApolloClient, InMemoryCache } from "@apollo/client";
 * import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
 *
 * const client = new ApolloClient({
 *   cache: new InMemoryCache(),
 *   link: createUploadLink(),
 * });
 * ```
 */
export default function createUploadLink({
  uri: fetchUri = "/graphql",
  useGETForQueries = false,
  print = defaultPrinter,
  fetch: customFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions,
}: CreateUploadLinkParams) {
  const linkConfig = {
    http: {includeExtensions},
    options: fetchOptions,
    credentials,
    headers,
  };

  return new ApolloLink(operation => {
    const context =
      /**
       * @type {import("@apollo/client/core/types.js").DefaultContext & {
       *   clientAwareness?: {
       *     name?: string,
       *     version?: string,
       *   },
       * }}
       */
      operation.getContext();
    const {
      // Apollo Studio client awareness `name` and `version` can be configured
      // via `ApolloClient` constructor options:
      // https://www.apollographql.com/docs/graphos/metrics/client-awareness/#setup
      clientAwareness: {name = undefined, version = undefined} = {},
      headers,
    } = context;

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: {
        // Client awareness headers can be overridden by context `headers`.
        ...(name && {"apollographql-client-name": name}),
        ...(version && {"apollographql-client-version": version}),
        ...headers,
      },
    };

    const {options, body} = selectHttpOptionsAndBodyInternal(
      operation,
      print,
      fallbackHttpConfig,
      linkConfig,
      contextConfig
    );

    const {clone, files} = extractFiles(body, isExtractableFile, "");

    let uri = selectURI(operation, fetchUri);

    if (files.size) {
      if (options.headers)
        // Automatically set by `fetch` when the `body` is a `FormData` instance.
        delete options.headers["content-type"];

      // GraphQL multipart request spec:
      // https://github.com/jaydenseric/graphql-multipart-request-spec

      const form = new FormData();

      form.append("operations", serializeFetchParameter(clone, "Payload"));

      const map: {[key: string]: string[]} = {};

      let i = 0;
      files.forEach(paths => {
        map[++i] = paths;
      });
      form.append("map", JSON.stringify(map));

      i = 0;
      files.forEach((_paths, file) => {
        formDataAppendFile(form, String(++i), file);
      });

      options.body = form;
    } else {
      if (
        useGETForQueries &&
        // If the operation contains some mutations GET shouldn’t be used.
        !operation.query.definitions.some(
          definition =>
            definition.kind === "OperationDefinition" &&
            definition.operation === "mutation"
        )
      )
        options.method = "GET";

      if (options.method === "GET") {
        const {newURI, parseError} = rewriteURIForGET(uri, body);
        if (parseError)
          // Apollo’s `HttpLink` uses `fromError` for this, but it’s not
          // exported from `@apollo/client/link/http`.
          return new Observable(observer => {
            observer.error(parseError);
          });
        uri = newURI;
      } else options.body = serializeFetchParameter(clone, "Payload");
    }

    const {controller} = createSignalIfSupported();

    if (typeof controller !== "boolean") {
      if (options.signal)
        // Respect the user configured abort controller signal.
        options.signal.aborted
          ? // Signal already aborted, so immediately abort.
            controller.abort()
          : // Signal not already aborted, so setup a listener to abort when it
            // does.
            options.signal.addEventListener(
              "abort",
              () => {
                controller.abort();
              },
              {
                // Prevent a memory leak if the user configured abort controller
                // is long lasting, or controls multiple things.
                once: true,
              }
            );

      options.signal = controller.signal;
    }

    const runtimeFetch = customFetch || fetch;

    return new Observable(observer => {
      /**
       * Is the observable being cleaned up.
       */
      let cleaningUp: boolean;

      runtimeFetch(uri, options)
        .then((response: any) => {
          // Forward the response on the context.
          operation.setContext({response});
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result: any) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error: any) => {
          // If the observable is being cleaned up, there is no need to call
          // next or error because there are no more subscribers. An error after
          // cleanup begins is likely from the cleanup function aborting the
          // fetch.
          if (!cleaningUp) {
            // For errors such as an invalid fetch URI there will be no GraphQL
            // result with errors or data to forward.
            if (error.result && error.result.errors && error.result.data)
              observer.next(error.result);

            observer.error(error);
          }
        });

      // Cleanup function.
      return () => {
        cleaningUp = true;

        // Abort fetch. It’s ok to signal an abort even when not fetching.
        if (typeof controller !== "boolean") controller.abort();
      };
    });
  });
}
