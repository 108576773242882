import {gql} from "@apollo/client";

import * as fragments from "./fragments";

// https://www.apollographql.com/docs/react/get-started

export const MAP_SEARCH = gql`
  ${fragments.PAGINATION_FIELDS}

  query MapSearch(
    $term: String
    $xmin: Float
    $ymin: Float
    $xmax: Float
    $ymax: Float
    $zoom: Float
    $beginDate: Date
    $endDate: Date
    $nights: Int
    $people: Int
    $strict: Boolean
    $firesAllowed: Boolean
    $petsAllowed: Boolean
    $accessible: Boolean
    $excludeGroup: Boolean
    $onlyMatches: Boolean
    $page: Int = 1
    $pageSize: Int = 100
    $sortField: String
    $sortDirection: SortDirection
    $cluster: Boolean = false
  ) {
    search {
      search(
        input: {
          term: $term
          boundingBox: {xmin: $xmin, ymin: $ymin, xmax: $xmax, ymax: $ymax}
          zoom: $zoom
          beginDate: $beginDate
          endDate: $endDate
          nights: $nights
          people: $people
          strict: $strict
          firesAllowed: $firesAllowed
          petsAllowed: $petsAllowed
          accessible: $accessible
          excludeGroup: $excludeGroup
          onlyMatches: $onlyMatches
          pagination: {page: $page, pageSize: $pageSize}
          sort: {field: $sortField, direction: $sortDirection}
          cluster: $cluster
        }
      ) {
        results {
          ... on SearchResult {
            type
            id
            name
            location {
              latitude
              longitude
            }
            isMatch
            isShared
            isWatched
            updatedAt
          }
          ... on MapCluster {
            name
            count
            location {
              latitude
              longitude
            }
            isMatch
            isShared
            isWatched
            expansionZoom
          }
        }
        pagination {
          ...PaginationFields
        }
      }
    }
  }
`;

export const PICKER_SEARCH = gql`
  query PickerSearch($term: String) {
    search {
      search(
        input: {
          term: $term
          onlyMatches: false
          pagination: {page: 1, pageSize: 10}
          cluster: false
        }
      ) {
        results {
          ... on SearchResult {
            type
            id
            name
            result {
              ... on Facility {
                park {
                  name
                }
              }
              ... on Unit {
                facility {
                  name
                }
              }
            }
            isMatch
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_DETAILS = gql`
  ${fragments.ORGANIZATION_FIELDS}

  query GetOrganizationDetails($id: Int!) {
    entities {
      organization(organizationId: $id) {
        ...OrganizationFields
      }
    }
  }
`;

export const GET_PARK_DETAILS = gql`
  ${fragments.PARK_FIELDS}

  query GetParkDetails($id: Int!) {
    entities {
      park(parkId: $id) {
        ...ParkFields
      }
    }
  }
`;

export const GET_FACILITY_DETAILS = gql`
  ${fragments.FACILITY_FIELDS}

  query GetFacilityDetails($id: Int!) {
    entities {
      facility(facilityId: $id) {
        ...FacilityFields
        park {
          id
          name
        }
      }
    }
  }
`;

export const GET_FACILITY_DETAILS_WITH_SLOTS = gql`
  ${fragments.FACILITY_COMMON_FIELDS}
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetFacilityDetailsWithSlots(
    $id: Int!
    $beginDate: Date!
    $endDate: Date!
  ) {
    entities {
      facility(facilityId: $id) {
        ...FacilityCommonFields
        park {
          id
          name
        }
        units {
          ...UnitFields
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
`;

export const GET_FACILITY_DETAILS_WITH_FILTERED_UNITS_AND_SLOTS = gql`
  ${fragments.FACILITY_COMMON_FIELDS}
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetFacilityDetailsWithFilteredUnitsAndSlots(
    $id: Int!
    $term: String
    $xmin: Float
    $ymin: Float
    $xmax: Float
    $ymax: Float
    $beginDate: Date!
    $endDate: Date!
    $nights: Int
    $people: Int
    $strict: Boolean
    $firesAllowed: Boolean
    $petsAllowed: Boolean
    $accessible: Boolean
    $excludeFCFS: Boolean
    $excludeGroup: Boolean
    $excludeUnreleased: Boolean
    $onlyMatches: Boolean
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    entities {
      facility(facilityId: $id) {
        ...FacilityCommonFields
        park {
          id
          name
        }
        units(
          input: {
            term: $term
            boundingBox: {xmin: $xmin, ymin: $ymin, xmax: $xmax, ymax: $ymax}
            beginDate: $beginDate
            endDate: $endDate
            nights: $nights
            people: $people
            strict: $strict
            firesAllowed: $firesAllowed
            petsAllowed: $petsAllowed
            accessible: $accessible
            excludeFCFS: $excludeFCFS
            excludeGroup: $excludeGroup
            excludeUnreleased: $excludeUnreleased
            onlyMatches: $onlyMatches
            pagination: {page: $page, pageSize: $pageSize}
            sort: {field: $sortField, direction: $sortDirection}
          }
        ) {
          ...UnitFields
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
`;

export const GET_UNIT_DETAILS = gql`
  ${fragments.UNIT_FIELDS}

  query GetUnitDetails($id: Int!) {
    entities {
      unit(unitId: $id) {
        ...UnitFields
        facility {
          id
          name
        }
      }
    }
  }
`;

export const GET_UNIT_DETAILS_WITH_SLOTS = gql`
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetUnitDetailsWithSlots($id: Int!, $beginDate: Date!, $endDate: Date!) {
    entities {
      unit(unitId: $id) {
        ...UnitFields
        slots(input: {beginDate: $beginDate, endDate: $endDate}) {
          ...SlotFields
        }
        facility {
          id
          name
        }
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  ${fragments.AUTHENTICATED_USER_FIELDS}

  query GetCurrentUser {
    users {
      currentUser {
        ...AuthenticatedUserFields
      }
    }
  }
`;

export const GET_MAX_DATE = gql`
  query GetMaxDate {
    search {
      maxDate
    }
  }
`;

export const GET_LOCATION = gql`
  query GetLocation {
    users {
      location {
        latitude
        longitude
      }
    }
  }
`;

export const GET_FEATURE_FLAG = gql`
  query GetFeatureFlag($flag: String!) {
    features {
      isEnabled(flag: $flag)
    }
  }
`;

export const GET_CHECKOUT_CONFIG = gql`
  query GetCheckoutConfig {
    subscriptions {
      checkoutConfig {
        publishableKey
        plans {
          id
          name
          description
          amount
          currency
          interval
        }
      }
    }
  }
`;

export const GET_WATCH_LIST = gql`
  ${fragments.WATCH}

  query GetWatchList($status: WatchStatus) {
    watches {
      watchList(status: $status) {
        ...WatchFields
      }
    }
  }
`;

export const GET_WATCH = gql`
  ${fragments.WATCH}

  query GetWatch($id: Int!) {
    watches {
      watch(watchId: $id) {
        ...WatchFields
      }
    }
  }
`;

export const GET_WATCH_FOUND_UNITS = gql`
  ${fragments.UNIT_FIELDS}
  ${fragments.SLOT_FIELDS}

  query GetWatchFound($id: Int!, $beginDate: Date!, $endDate: Date!) {
    watches {
      watch(watchId: $id) {
        id
        foundUnits {
          ...UnitFields
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
`;

export const SEARCH_PLACES = gql`
  ${fragments.PLACE}

  query SearchPlaces(
    $term: String!
    $near: LocationInput
    $sessionToken: String
    $page: Int
    $pageSize: Int
  ) {
    places {
      search(
        input: {
          term: $term
          near: $near
          sessionToken: $sessionToken
          pagination: {page: $page, pageSize: $pageSize}
        }
      ) {
        results {
          ...PlaceFields
        }
        sessionToken
      }
    }
  }
`;

export const GET_PLACE_LOCATION = gql`
  query GetPlaceLocation($id: String!, $sessionToken: String) {
    places {
      location(placeId: $id, sessionToken: $sessionToken) {
        latitude
        longitude
      }
    }
  }
`;

export const GET_RECENTLY_CHECKED_FACILITIES = gql`
  query GetRecentlyCheckedFacilities {
    entities {
      recentlyCheckedFacilities {
        id
        name
        park {
          id
          name
        }
        lastCheckedAt
      }
    }
  }
`;

export const CALCULATE_USED_CREDITS = gql`
  query CalculateUsedCredits(
    $parkIds: [Int!]!
    $facilityIds: [Int!]!
    $unitIds: [Int!]!
  ) {
    subscriptions {
      calculateUsedCredits(
        parkIds: $parkIds
        facilityIds: $facilityIds
        unitIds: $unitIds
      )
    }
  }
`;
