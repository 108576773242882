// Name of the cookie the session key is stored in
export const TOKEN_COOKIE_NAME = "token";

// If a slot hasn't been checked in this many days, it's considered stale
export const SLOT_STALE_THRESHOLD_DAYS = 1;

export const PAGE_TITLE = "Camp Observer";
export const PAGE_DESCRIPTION =
  "Camp Observer makes it easy to find and reserve camp sites";

export const NOT_FOUND_IMAGE_URL = "/images/image_not_found.jpg";

export const URL_DATE_FORMAT = "YYYY-MM-DD";

// TODO: Remove this requirement after beta period
export const SIGNUP_REASON_REQUIRED = true;
