import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Date: {input: any; output: any};
  DateTime: {input: any; output: any};
  Upload: {input: any; output: any};
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  street: Array<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type BoundingBoxInput = {
  xmax?: InputMaybe<Scalars["Float"]["input"]>;
  xmin?: InputMaybe<Scalars["Float"]["input"]>;
  ymax?: InputMaybe<Scalars["Float"]["input"]>;
  ymin?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChatMessage = {
  __typename?: "ChatMessage";
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  recipient?: Maybe<User>;
  sender?: Maybe<User>;
  sentAt: Scalars["DateTime"]["output"];
};

export type ChatMessageInput = {
  message: Scalars["String"]["input"];
  recipientId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatMutation = {
  __typename?: "ChatMutation";
  sendMessage: ChatMessage;
};

export type ChatMutationSendMessageArgs = {
  input: ChatMessageInput;
};

export type CheckoutConfig = {
  __typename?: "CheckoutConfig";
  plans: Array<SubscriptionPlan>;
  publishableKey: Scalars["String"]["output"];
};

export type Closure = {
  __typename?: "Closure";
  beginsAt?: Maybe<Scalars["DateTime"]["output"]>;
  endsAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type CreateSubscriptionResult = {
  __typename?: "CreateSubscriptionResult";
  clientSecret: Scalars["String"]["output"];
  customerId: Scalars["String"]["output"];
  planId: Scalars["String"]["output"];
  subscriptionId: Scalars["String"]["output"];
};

export type DateRange = {
  __typename?: "DateRange";
  max?: Maybe<Scalars["Date"]["output"]>;
  min?: Maybe<Scalars["Date"]["output"]>;
};

export type DateRangeInput = {
  max?: InputMaybe<Scalars["Date"]["input"]>;
  min?: InputMaybe<Scalars["Date"]["input"]>;
};

export type EntitiesMutation = {
  __typename?: "EntitiesMutation";
  refreshAddress: Address;
  refreshClosure: Closure;
  refreshFacility: Facility;
  refreshMedia: Media;
  refreshPark: Park;
  refreshUnit: Unit;
  updateAddress: Address;
  updateClosure: Closure;
  updateFacility: Facility;
  updateMedia: Media;
  updatePark: Park;
  updateUnit: Unit;
};

export type EntitiesMutationRefreshAddressArgs = {
  addressId: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type EntitiesMutationRefreshClosureArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  closureId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshFacilityArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  facilityId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshMediaArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  mediaId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshParkArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  parkId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshUnitArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  unitId: Scalars["Int"]["input"];
};

export type EntitiesMutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};

export type EntitiesMutationUpdateClosureArgs = {
  input: UpdateClosureInput;
};

export type EntitiesMutationUpdateFacilityArgs = {
  input: UpdateFacilityInput;
};

export type EntitiesMutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type EntitiesMutationUpdateParkArgs = {
  input: UpdateParkInput;
};

export type EntitiesMutationUpdateUnitArgs = {
  input: UpdateUnitInput;
};

export type EntitiesQuery = {
  __typename?: "EntitiesQuery";
  address?: Maybe<Address>;
  closure?: Maybe<Closure>;
  equipment?: Maybe<Equipment>;
  facility?: Maybe<Facility>;
  media?: Maybe<Media>;
  organization?: Maybe<Organization>;
  park?: Maybe<Park>;
  recentlyCheckedFacilities: Array<Facility>;
  slot?: Maybe<Slot>;
  unit?: Maybe<Unit>;
};

export type EntitiesQueryAddressArgs = {
  addressId: Scalars["Int"]["input"];
};

export type EntitiesQueryClosureArgs = {
  closureId: Scalars["Int"]["input"];
};

export type EntitiesQueryEquipmentArgs = {
  equipmentId: Scalars["Int"]["input"];
};

export type EntitiesQueryFacilityArgs = {
  facilityId: Scalars["Int"]["input"];
};

export type EntitiesQueryMediaArgs = {
  mediaId: Scalars["Int"]["input"];
};

export type EntitiesQueryOrganizationArgs = {
  organizationId: Scalars["Int"]["input"];
};

export type EntitiesQueryParkArgs = {
  parkId: Scalars["Int"]["input"];
};

export type EntitiesQuerySlotArgs = {
  slotId: Scalars["Int"]["input"];
};

export type EntitiesQueryUnitArgs = {
  unitId: Scalars["Int"]["input"];
};

export type Equipment = {
  __typename?: "Equipment";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Facility = Mapable & {
  __typename?: "Facility";
  addresses: Array<Address>;
  bookingUrl?: Maybe<Scalars["String"]["output"]>;
  checkinHour?: Maybe<Scalars["Int"]["output"]>;
  checkoutHour?: Maybe<Scalars["Int"]["output"]>;
  closures: Array<Closure>;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  directions?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  favoritesCount?: Maybe<Scalars["Int"]["output"]>;
  feeDescription?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isAdaAccessible?: Maybe<Scalars["Boolean"]["output"]>;
  isReservable?: Maybe<Scalars["Boolean"]["output"]>;
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  lastCheckedAt?: Maybe<Scalars["DateTime"]["output"]>;
  location?: Maybe<Location>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  media: Array<Media>;
  name?: Maybe<Scalars["String"]["output"]>;
  nearbyFacilities: Array<Facility>;
  organization?: Maybe<Organization>;
  park?: Maybe<Park>;
  phone?: Maybe<Scalars["String"]["output"]>;
  primaryMedia?: Maybe<Media>;
  reservationUrl?: Maybe<Scalars["String"]["output"]>;
  rules?: Maybe<Scalars["String"]["output"]>;
  stayLimit?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  unitCount: Scalars["Int"]["output"];
  units: Array<Unit>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type FacilityUnitsArgs = {
  input?: InputMaybe<SearchInput>;
};

export type FeaturesQuery = {
  __typename?: "FeaturesQuery";
  isEnabled: Scalars["Boolean"]["output"];
};

export type FeaturesQueryIsEnabledArgs = {
  flag: Scalars["String"]["input"];
};

export type FormError = {
  __typename?: "FormError";
  field?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type IntRange = {
  __typename?: "IntRange";
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
};

export type IntRangeInput = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
  min?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Location = {
  __typename?: "Location";
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
};

export type LocationInput = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type MapCluster = Mapable &
  SearchMapable & {
    __typename?: "MapCluster";
    count: Scalars["Int"]["output"];
    expansionZoom: Scalars["Int"]["output"];
    isMatch: Scalars["Boolean"]["output"];
    isShared: Scalars["Boolean"]["output"];
    isWatched: Scalars["Boolean"]["output"];
    location?: Maybe<Location>;
    name?: Maybe<Scalars["String"]["output"]>;
  };

export type Mapable = {
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type Media = {
  __typename?: "Media";
  credits?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive: Scalars["Boolean"]["output"];
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  chat: ChatMutation;
  entities: EntitiesMutation;
  subscriptions: SubscriptionsMutation;
  uploads: UploadsMutation;
  users: UsersMutation;
  watches: WatchesMutation;
};

export type Organization = {
  __typename?: "Organization";
  addresses: Array<Address>;
  facilities: Array<Facility>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parks: Array<Park>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type PaginationInput = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaginationResults = {
  __typename?: "PaginationResults";
  page: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
  totalResults: Scalars["Int"]["output"];
};

export type Park = Mapable & {
  __typename?: "Park";
  addresses: Array<Address>;
  closures: Array<Closure>;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  directions?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  facilities: Array<Facility>;
  favoritesCount?: Maybe<Scalars["Int"]["output"]>;
  feeDescription?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isReservable?: Maybe<Scalars["Boolean"]["output"]>;
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  media: Array<Media>;
  name?: Maybe<Scalars["String"]["output"]>;
  nearbyParks: Array<Park>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars["String"]["output"]>;
  polygonGeoJson?: Maybe<Scalars["String"]["output"]>;
  primaryMedia?: Maybe<Media>;
  reservationUrl?: Maybe<Scalars["String"]["output"]>;
  stayLimit?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export enum Permissions {
  Admin = "ADMIN",
}

export type Place = Mapable & {
  __typename?: "Place";
  id: Scalars["String"]["output"];
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  parent?: Maybe<Place>;
  placeType: Scalars["String"]["output"];
  polygon?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
};

export type PlacesInput = {
  near?: InputMaybe<LocationInput>;
  pagination?: InputMaybe<PaginationInput>;
  placeTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sessionToken?: InputMaybe<Scalars["String"]["input"]>;
  term?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlacesQuery = {
  __typename?: "PlacesQuery";
  location?: Maybe<Location>;
  search: PlacesResults;
};

export type PlacesQueryLocationArgs = {
  placeId: Scalars["String"]["input"];
  sessionToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlacesQuerySearchArgs = {
  input: PlacesInput;
};

export type PlacesResults = {
  __typename?: "PlacesResults";
  pagination: PaginationResults;
  results: Array<Place>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  entities: EntitiesQuery;
  features: FeaturesQuery;
  places: PlacesQuery;
  search: SearchQuery;
  subscriptions: SubscriptionsQuery;
  users: UsersQuery;
  watches: WatchesQuery;
};

export type ResetPasswordInput = {
  newPassword: Scalars["String"]["input"];
  newPasswordConfirmation: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type ResetPasswordRequestInput = {
  captchaResponse: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type ResetPasswordResult = FormError | ResetPasswordSuccessResult;

export type ResetPasswordSuccessResult = {
  __typename?: "ResetPasswordSuccessResult";
  email: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type SearchInput = {
  accessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  beginDate?: InputMaybe<Scalars["Date"]["input"]>;
  boundingBox?: InputMaybe<BoundingBoxInput>;
  cluster?: Scalars["Boolean"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  excludeFCFS?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeUnreleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  firesAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  nights?: InputMaybe<Scalars["Int"]["input"]>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
  people?: InputMaybe<Scalars["Int"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  sort?: InputMaybe<SortOptionInput>;
  strict?: InputMaybe<Scalars["Boolean"]["input"]>;
  term?: InputMaybe<Scalars["String"]["input"]>;
  zoom?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SearchMapable = {
  isMatch: Scalars["Boolean"]["output"];
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type SearchQuery = {
  __typename?: "SearchQuery";
  maxDate?: Maybe<Scalars["Date"]["output"]>;
  search: SearchResults;
};

export type SearchQuerySearchArgs = {
  input: SearchInput;
};

export type SearchResult = Mapable &
  SearchMapable & {
    __typename?: "SearchResult";
    id: Scalars["String"]["output"];
    isMatch: Scalars["Boolean"]["output"];
    isShared: Scalars["Boolean"]["output"];
    isWatched: Scalars["Boolean"]["output"];
    location?: Maybe<Location>;
    name?: Maybe<Scalars["String"]["output"]>;
    result?: Maybe<SearchResultType>;
    type: Scalars["String"]["output"];
    updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  };

export type SearchResultMapCluster = MapCluster | SearchResult;

export type SearchResultType = Facility | Park | Place | Unit;

export type SearchResults = {
  __typename?: "SearchResults";
  pagination: PaginationResults;
  results: Array<SearchResultMapCluster>;
};

export type SignUpInput = {
  acceptedTerms: Scalars["Boolean"]["input"];
  captchaResponse: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  referral?: InputMaybe<Scalars["String"]["input"]>;
};

export type SignUpResult = FormError | SignUpSuccessResult;

export type SignUpSuccessResult = {
  __typename?: "SignUpSuccessResult";
  email: Scalars["String"]["output"];
  userId: Scalars["Int"]["output"];
};

export type Slot = {
  __typename?: "Slot";
  id?: Maybe<Scalars["Int"]["output"]>;
  reservableAt?: Maybe<Scalars["DateTime"]["output"]>;
  slotDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<SlotStatus>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export enum SlotStatus {
  Available = "AVAILABLE",
  Closed = "CLOSED",
  Lottery = "LOTTERY",
  NotReservable = "NOT_RESERVABLE",
  Reserved = "RESERVED",
  Unavailable = "UNAVAILABLE",
  Unreleased = "UNRELEASED",
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type SortOptionInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<Scalars["String"]["input"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  chatMessages: ChatMessage;
  count: Scalars["Int"]["output"];
};

export type SubscriptionCountArgs = {
  target?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan";
  active: Scalars["Boolean"]["output"];
  amount: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  interval: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
};

export type SubscriptionsMutation = {
  __typename?: "SubscriptionsMutation";
  cancel: Scalars["Boolean"]["output"];
  create: CreateSubscriptionResult;
};

export type SubscriptionsMutationCancelArgs = {
  subscriptionId: Scalars["String"]["input"];
};

export type SubscriptionsMutationCreateArgs = {
  planId: Scalars["String"]["input"];
};

export type SubscriptionsQuery = {
  __typename?: "SubscriptionsQuery";
  calculateUsedCredits: Scalars["Int"]["output"];
  checkoutConfig: CheckoutConfig;
};

export type SubscriptionsQueryCalculateUsedCreditsArgs = {
  facilityIds: Array<Scalars["Int"]["input"]>;
  parkIds: Array<Scalars["Int"]["input"]>;
  unitIds: Array<Scalars["Int"]["input"]>;
};

export type Unit = Mapable & {
  __typename?: "Unit";
  allowedEquipment: Array<Equipment>;
  bookingUrl?: Maybe<Scalars["String"]["output"]>;
  closures: Array<Closure>;
  facility?: Maybe<Facility>;
  favoritesCount?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isAdaAccessible?: Maybe<Scalars["Boolean"]["output"]>;
  isEquipmentMandatory?: Maybe<Scalars["Boolean"]["output"]>;
  isGroup?: Maybe<Scalars["Boolean"]["output"]>;
  isReservable?: Maybe<Scalars["Boolean"]["output"]>;
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  loopName?: Maybe<Scalars["String"]["output"]>;
  maxVehicleLengthFeet?: Maybe<Scalars["Int"]["output"]>;
  maxVehicles?: Maybe<Scalars["Int"]["output"]>;
  media: Array<Media>;
  name?: Maybe<Scalars["String"]["output"]>;
  nearbyUnits: Array<Unit>;
  peopleCapacity?: Maybe<IntRange>;
  petsAllowed?: Maybe<Scalars["Boolean"]["output"]>;
  primaryMedia?: Maybe<Media>;
  rules?: Maybe<Scalars["String"]["output"]>;
  slots: Array<Slot>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  useType?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type UnitSlotsArgs = {
  input: UnitSlotsInput;
};

export type UnitSlotsInput = {
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
};

export type UpdateAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateClosureInput = {
  beginsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UpdateFacilityInput = {
  bookingUrl?: InputMaybe<Scalars["String"]["input"]>;
  checkinHour?: InputMaybe<Scalars["Int"]["input"]>;
  checkoutHour?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  numSites?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  parkId?: InputMaybe<Scalars["Int"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateMediaInput = {
  credits?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateParkInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UpdateUnitInput = {
  allowedEquipmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  facilityId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  loopName?: InputMaybe<Scalars["String"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  peopleCapacity?: InputMaybe<IntRangeInput>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  useType?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserSettingsInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  newPassword?: InputMaybe<Scalars["String"]["input"]>;
  oldPassword?: InputMaybe<Scalars["String"]["input"]>;
  partialSettingsJson?: InputMaybe<Scalars["String"]["input"]>;
};

export type UploadMappingResult = {
  __typename?: "UploadMappingResult";
  fileFormat?: Maybe<Scalars["String"]["output"]>;
  fileName: Scalars["String"]["output"];
  rows: Array<UploadMappingRow>;
  uploadId: Scalars["String"]["output"];
};

export type UploadMappingRow = Mapable & {
  __typename?: "UploadMappingRow";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  unit?: Maybe<Unit>;
};

export type UploadsMutation = {
  __typename?: "UploadsMutation";
  uploadMapping: UploadMappingResult;
};

export type UploadsMutationUploadMappingArgs = {
  file: Scalars["Upload"]["input"];
  name: Scalars["String"]["input"];
};

export type User = {
  __typename?: "User";
  avatarImageUrl?: Maybe<Scalars["String"]["output"]>;
  displayName: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  favorites?: Maybe<UserFavorites>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  maxWatchCredits: Scalars["Int"]["output"];
  permissions?: Maybe<Array<Permissions>>;
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  settingsJson?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<UserStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  usedWatchCredits: Scalars["Int"]["output"];
  version?: Maybe<Scalars["Int"]["output"]>;
};

export type UserAvatarImageUrlArgs = {
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserFavorites = {
  __typename?: "UserFavorites";
  facilities: Array<Facility>;
  parks: Array<Park>;
  units: Array<Unit>;
};

export enum UserStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Suspended = "SUSPENDED",
}

export type UsersMutation = {
  __typename?: "UsersMutation";
  resetPassword: ResetPasswordResult;
  resetPasswordRequest: ResetPasswordResult;
  signUp: SignUpResult;
  updateSettings: User;
};

export type UsersMutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type UsersMutationResetPasswordRequestArgs = {
  input: ResetPasswordRequestInput;
};

export type UsersMutationSignUpArgs = {
  input: SignUpInput;
};

export type UsersMutationUpdateSettingsArgs = {
  input: UpdateUserSettingsInput;
};

export type UsersQuery = {
  __typename?: "UsersQuery";
  allUsers: Array<User>;
  currentUser?: Maybe<User>;
  location?: Maybe<Location>;
  user?: Maybe<User>;
};

export type UsersQueryUserArgs = {
  userId: Scalars["Int"]["input"];
};

export type Watch = {
  __typename?: "Watch";
  alertsEnabled: Scalars["Boolean"]["output"];
  arrival: DateRange;
  consecutiveDays?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creator: User;
  displayName: Scalars["String"]["output"];
  effectiveFacilities: Array<Facility>;
  effectiveUnits: Array<Unit>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  facilities: Array<Facility>;
  filter: WatchFilter;
  foundFacilities: Array<Facility>;
  foundUnits: Array<Unit>;
  id: Scalars["Int"]["output"];
  lastScannedAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parks: Array<Park>;
  scheduleDescription: Scalars["String"]["output"];
  status: WatchStatus;
  units: Array<Unit>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type WatchFilter = {
  __typename?: "WatchFilter";
  adaAccessible?: Maybe<Scalars["Boolean"]["output"]>;
  isEquipmentMandatory?: Maybe<Scalars["Boolean"]["output"]>;
  isGroup?: Maybe<Scalars["Boolean"]["output"]>;
  maxVehicleLengthFeet?: Maybe<Scalars["Int"]["output"]>;
  maxVehicles?: Maybe<Scalars["Int"]["output"]>;
  people: IntRange;
  petsAllowed?: Maybe<Scalars["Boolean"]["output"]>;
};

export type WatchFilterInput = {
  adaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  people: IntRangeInput;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WatchInput = {
  alertsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  arrival: DateRangeInput;
  consecutiveDays?: InputMaybe<Scalars["Int"]["input"]>;
  facilityIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  filter?: InputMaybe<WatchFilterInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parkIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  unitIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export enum WatchStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Inactive = "INACTIVE",
  Paused = "PAUSED",
  Triggered = "TRIGGERED",
}

export type WatchesMutation = {
  __typename?: "WatchesMutation";
  cancel: Watch;
  create: Watch;
  update: Watch;
};

export type WatchesMutationCancelArgs = {
  watchId: Scalars["Int"]["input"];
};

export type WatchesMutationCreateArgs = {
  input: WatchInput;
};

export type WatchesMutationUpdateArgs = {
  input: WatchInput;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  watchId: Scalars["Int"]["input"];
};

export type WatchesQuery = {
  __typename?: "WatchesQuery";
  watch: Watch;
  watchList: Array<Watch>;
};

export type WatchesQueryWatchArgs = {
  watchId: Scalars["Int"]["input"];
};

export type WatchesQueryWatchListArgs = {
  status?: InputMaybe<WatchStatus>;
};

export type PaginationFieldsFragment = {
  __typename?: "PaginationResults";
  page: number;
  pageSize: number;
  totalResults: number;
};

export type AddressFieldsFragment = {
  __typename?: "Address";
  id: number;
  type?: string | null;
  street: Array<string>;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
};

export type ClosureFieldsFragment = {
  __typename?: "Closure";
  id: number;
  beginsAt?: any | null;
  endsAt?: any | null;
  reason?: string | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
};

export type EquipmentFieldsFragment = {
  __typename?: "Equipment";
  id: number;
  name: string;
  description?: string | null;
  isActive: boolean;
  updatedAt: any;
};

export type MediaFieldsFragment = {
  __typename?: "Media";
  id?: number | null;
  type?: string | null;
  title?: string | null;
  subtitle?: string | null;
  description?: string | null;
  credits?: string | null;
  url?: string | null;
  width?: number | null;
  height?: number | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
};

export type MediaMarkerFieldsFragment = {
  __typename?: "Media";
  id?: number | null;
  title?: string | null;
  url?: string | null;
  width?: number | null;
  height?: number | null;
};

export type FacilityMarkerFieldsFragment = {
  __typename?: "Facility";
  id: number;
  name?: string | null;
  lastCheckedAt?: any | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    title?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

export type UnitMarkerFieldsFragment = {
  __typename?: "Unit";
  id: number;
  name?: string | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    title?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  facility?: {
    __typename?: "Facility";
    id: number;
    name?: string | null;
    lastCheckedAt?: any | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      title?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
    } | null;
  } | null;
};

export type UnitFieldsFragment = {
  __typename?: "Unit";
  id: number;
  type?: string | null;
  useType?: string | null;
  name?: string | null;
  loopName?: string | null;
  bookingUrl?: string | null;
  rules?: string | null;
  isGroup?: boolean | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  petsAllowed?: boolean | null;
  maxVehicles?: number | null;
  maxVehicleLengthFeet?: number | null;
  favoritesCount?: number | null;
  isEquipmentMandatory?: boolean | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  peopleCapacity?: {
    __typename?: "IntRange";
    min?: number | null;
    max?: number | null;
  } | null;
  facility?: {
    __typename?: "Facility";
    id: number;
    name?: string | null;
    park?: {__typename?: "Park"; id: number; name?: string | null} | null;
  } | null;
  allowedEquipment: Array<{
    __typename?: "Equipment";
    id: number;
    name: string;
    description?: string | null;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
};

export type FacilityUnitFieldsFragment = {
  __typename?: "Unit";
  id: number;
  type?: string | null;
  useType?: string | null;
  name?: string | null;
  loopName?: string | null;
  bookingUrl?: string | null;
  rules?: string | null;
  isGroup?: boolean | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  petsAllowed?: boolean | null;
  maxVehicles?: number | null;
  maxVehicleLengthFeet?: number | null;
  isEquipmentMandatory?: boolean | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  peopleCapacity?: {
    __typename?: "IntRange";
    min?: number | null;
    max?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
};

export type FacilityCommonFieldsFragment = {
  __typename?: "Facility";
  id: number;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  unitCount: number;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  lastCheckedAt?: any | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
};

export type FacilityFieldsFragment = {
  __typename?: "Facility";
  id: number;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  unitCount: number;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  lastCheckedAt?: any | null;
  units: Array<{
    __typename?: "Unit";
    id: number;
    type?: string | null;
    useType?: string | null;
    name?: string | null;
    loopName?: string | null;
    bookingUrl?: string | null;
    rules?: string | null;
    isGroup?: boolean | null;
    isReservable?: boolean | null;
    isAdaAccessible?: boolean | null;
    petsAllowed?: boolean | null;
    maxVehicles?: number | null;
    maxVehicleLengthFeet?: number | null;
    isEquipmentMandatory?: boolean | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    peopleCapacity?: {
      __typename?: "IntRange";
      min?: number | null;
      max?: number | null;
    } | null;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    } | null;
  }>;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
};

export type FacilityWithoutUnitsFieldsFragment = {
  __typename?: "Facility";
  unitCount: number;
  id: number;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  lastCheckedAt?: any | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
};

export type OrganizationFieldsFragment = {
  __typename?: "Organization";
  id: number;
  name: string;
  url?: string | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
};

export type ParkMarkerFieldsFragment = {
  __typename?: "Park";
  id: number;
  name?: string | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    title?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

export type ParkFieldsFragment = {
  __typename?: "Park";
  id: number;
  name?: string | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  isReservable?: boolean | null;
  polygonGeoJson?: string | null;
  favoritesCount?: number | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  facilities: Array<{
    __typename?: "Facility";
    unitCount: number;
    id: number;
    type?: string | null;
    name?: string | null;
    url?: string | null;
    bookingUrl?: string | null;
    reservationUrl?: string | null;
    mapUrl?: string | null;
    checkinHour?: number | null;
    checkoutHour?: number | null;
    description?: string | null;
    descriptionHtml?: string | null;
    feeDescription?: string | null;
    rules?: string | null;
    directions?: string | null;
    stayLimit?: string | null;
    phone?: string | null;
    email?: string | null;
    isReservable?: boolean | null;
    isAdaAccessible?: boolean | null;
    favoritesCount?: number | null;
    isWatched: boolean;
    isShared: boolean;
    version: number;
    isActive: boolean;
    updatedAt: any;
    lastCheckedAt?: any | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    addresses: Array<{
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    closures: Array<{
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    media: Array<{
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    } | null;
  }>;
};

export type SlotFieldsFragment = {
  __typename?: "Slot";
  id?: number | null;
  slotDate?: any | null;
  status?: SlotStatus | null;
  version: number;
  updatedAt: any;
};

export type UserFieldsFragment = {
  __typename?: "User";
  id: number;
  displayName: string;
  avatarImageUrl?: string | null;
};

export type AuthenticatedUserFieldsFragment = {
  __typename?: "User";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  settingsJson?: string | null;
  maxWatchCredits: number;
  usedWatchCredits: number;
  permissions?: Array<Permissions> | null;
  version?: number | null;
  isActive?: boolean | null;
  updatedAt?: any | null;
  id: number;
  displayName: string;
  avatarImageUrl?: string | null;
};

export type AdminUserFieldsFragment = {
  __typename?: "User";
  roles?: Array<string> | null;
};

export type ChatMessageFieldsFragment = {
  __typename?: "ChatMessage";
  id?: string | null;
  message: string;
  sentAt: any;
  deletedAt?: any | null;
  sender?: {
    __typename?: "User";
    id: number;
    displayName: string;
    avatarImageUrl?: string | null;
  } | null;
  recipient?: {
    __typename?: "User";
    id: number;
    displayName: string;
    avatarImageUrl?: string | null;
  } | null;
};

export type WatchFieldsFragment = {
  __typename?: "Watch";
  id: number;
  name?: string | null;
  displayName: string;
  consecutiveDays?: number | null;
  scheduleDescription: string;
  status: WatchStatus;
  expiresAt?: any | null;
  lastScannedAt?: any | null;
  alertsEnabled: boolean;
  version: number;
  createdAt: any;
  updatedAt: any;
  creator: {__typename?: "User"; id: number};
  filter: {
    __typename?: "WatchFilter";
    isGroup?: boolean | null;
    petsAllowed?: boolean | null;
    adaAccessible?: boolean | null;
    isEquipmentMandatory?: boolean | null;
    maxVehicleLengthFeet?: number | null;
    maxVehicles?: number | null;
    people: {__typename?: "IntRange"; min?: number | null; max?: number | null};
  };
  arrival: {__typename?: "DateRange"; min?: any | null; max?: any | null};
  parks: Array<{__typename?: "Park"; id: number; name?: string | null}>;
  facilities: Array<{
    __typename?: "Facility";
    id: number;
    name?: string | null;
    park?: {__typename?: "Park"; id: number; name?: string | null} | null;
  }>;
  units: Array<{
    __typename?: "Unit";
    id: number;
    name?: string | null;
    facility?: {
      __typename?: "Facility";
      id: number;
      name?: string | null;
      park?: {__typename?: "Park"; id: number; name?: string | null} | null;
    } | null;
  }>;
  effectiveFacilities: Array<{
    __typename?: "Facility";
    id: number;
    name?: string | null;
    park?: {__typename?: "Park"; id: number; name?: string | null} | null;
  }>;
  foundFacilities: Array<{
    __typename?: "Facility";
    id: number;
    name?: string | null;
    park?: {__typename?: "Park"; id: number; name?: string | null} | null;
  }>;
};

export type PlaceFieldsFragment = {
  __typename?: "Place";
  id: string;
  name?: string | null;
  region?: string | null;
  placeType: string;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  captchaResponse: Scalars["String"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  referral?: InputMaybe<Scalars["String"]["input"]>;
  acceptedTerms: Scalars["Boolean"]["input"];
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    signUp:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {__typename?: "SignUpSuccessResult"; userId: number; email: string};
  };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
  newPasswordConfirmation: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    resetPassword:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {
          __typename?: "ResetPasswordSuccessResult";
          success: boolean;
          email: string;
        };
  };
};

export type ResetPasswordRequestMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  captchaResponse: Scalars["String"]["input"];
}>;

export type ResetPasswordRequestMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    resetPasswordRequest:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {__typename?: "ResetPasswordSuccessResult"; success: boolean};
  };
};

export type UpdateUserSettingsMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  oldPassword?: InputMaybe<Scalars["String"]["input"]>;
  newPassword?: InputMaybe<Scalars["String"]["input"]>;
  partialSettingsJson?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    updateSettings: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      settingsJson?: string | null;
      maxWatchCredits: number;
      usedWatchCredits: number;
      permissions?: Array<Permissions> | null;
      version?: number | null;
      isActive?: boolean | null;
      updatedAt?: any | null;
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    };
  };
};

export type UpdateUnitMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  useType?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  loopName?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<LocationInput>;
  peopleCapacity?: InputMaybe<IntRangeInput>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  facilityId?: InputMaybe<Scalars["Int"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowedEquipmentIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateUnitMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateUnit: {
      __typename?: "Unit";
      id: number;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      facility?: {
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    };
  };
};

export type UpdateFacilityMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  bookingUrl?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  checkinHour?: InputMaybe<Scalars["Int"]["input"]>;
  checkoutHour?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  parkId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateFacilityMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateFacility: {
      __typename?: "Facility";
      id: number;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      lastCheckedAt?: any | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    };
  };
};

export type UpdateParkMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  name?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateParkMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updatePark: {
      __typename?: "Park";
      id: number;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      polygonGeoJson?: string | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
    };
  };
};

export type UpdateMediaMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  credits?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateMediaMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateMedia: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  city?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateAddressMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateAddress: {
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type UpdateClosureMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  beginsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateClosureMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateClosure: {
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type RefreshUnitMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshUnitMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshUnit: {
      __typename?: "Unit";
      id: number;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      facility?: {
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    };
  };
};

export type RefreshFacilityMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshFacilityMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshFacility: {
      __typename?: "Facility";
      id: number;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      lastCheckedAt?: any | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    };
  };
};

export type RefreshParkMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshParkMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshPark: {
      __typename?: "Park";
      id: number;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      polygonGeoJson?: string | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
    };
  };
};

export type RefreshMediaMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshMediaMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshMedia: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type RefreshAddressMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshAddressMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshAddress: {
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type RefreshClosureMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshClosureMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshClosure: {
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type SendChatMessageMutationVariables = Exact<{
  message: Scalars["String"]["input"];
}>;

export type SendChatMessageMutation = {
  __typename?: "Mutation";
  chat: {
    __typename?: "ChatMutation";
    sendMessage: {
      __typename?: "ChatMessage";
      id?: string | null;
      message: string;
      sentAt: any;
      deletedAt?: any | null;
      sender?: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      } | null;
      recipient?: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      } | null;
    };
  };
};

export type UploadMappingMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  file: Scalars["Upload"]["input"];
}>;

export type UploadMappingMutation = {
  __typename?: "Mutation";
  uploads: {
    __typename?: "UploadsMutation";
    uploadMapping: {
      __typename?: "UploadMappingResult";
      uploadId: string;
      fileName: string;
      fileFormat?: string | null;
      rows: Array<{
        __typename?: "UploadMappingRow";
        id: string;
        name?: string | null;
        description?: string | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          id: number;
          name?: string | null;
          loopName?: string | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            name?: string | null;
            park?: {
              __typename?: "Park";
              id: number;
              name?: string | null;
            } | null;
          } | null;
        } | null;
      }>;
    };
  };
};

export type CreateSubscriptionMutationVariables = Exact<{
  planId: Scalars["String"]["input"];
}>;

export type CreateSubscriptionMutation = {
  __typename?: "Mutation";
  subscriptions: {
    __typename?: "SubscriptionsMutation";
    create: {
      __typename?: "CreateSubscriptionResult";
      subscriptionId: string;
      clientSecret: string;
    };
  };
};

export type CancelSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars["String"]["input"];
}>;

export type CancelSubscriptionMutation = {
  __typename?: "Mutation";
  subscriptions: {__typename?: "SubscriptionsMutation"; cancel: boolean};
};

export type CreateWatchMutationVariables = Exact<{
  name?: InputMaybe<Scalars["String"]["input"]>;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  minPeople?: InputMaybe<Scalars["Int"]["input"]>;
  maxPeople?: InputMaybe<Scalars["Int"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  adaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  minArrival: Scalars["Date"]["input"];
  maxArrival: Scalars["Date"]["input"];
  consecutiveDays?: InputMaybe<Scalars["Int"]["input"]>;
  alertsEnabled: Scalars["Boolean"]["input"];
  parkIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  facilityIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  unitIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
}>;

export type CreateWatchMutation = {
  __typename?: "Mutation";
  watches: {
    __typename?: "WatchesMutation";
    create: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      consecutiveDays?: number | null;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "WatchFilter";
        isGroup?: boolean | null;
        petsAllowed?: boolean | null;
        adaAccessible?: boolean | null;
        isEquipmentMandatory?: boolean | null;
        maxVehicleLengthFeet?: number | null;
        maxVehicles?: number | null;
        people: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        };
      };
      arrival: {__typename?: "DateRange"; min?: any | null; max?: any | null};
      parks: Array<{__typename?: "Park"; id: number; name?: string | null}>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
    };
  };
};

export type UpdateWatchMutationVariables = Exact<{
  watchId: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  minPeople?: InputMaybe<Scalars["Int"]["input"]>;
  maxPeople?: InputMaybe<Scalars["Int"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  adaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  minArrival?: InputMaybe<Scalars["Date"]["input"]>;
  maxArrival?: InputMaybe<Scalars["Date"]["input"]>;
  consecutiveDays?: InputMaybe<Scalars["Int"]["input"]>;
  alertsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  parkIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  facilityIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  unitIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
}>;

export type UpdateWatchMutation = {
  __typename?: "Mutation";
  watches: {
    __typename?: "WatchesMutation";
    update: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      consecutiveDays?: number | null;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "WatchFilter";
        isGroup?: boolean | null;
        petsAllowed?: boolean | null;
        adaAccessible?: boolean | null;
        isEquipmentMandatory?: boolean | null;
        maxVehicleLengthFeet?: number | null;
        maxVehicles?: number | null;
        people: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        };
      };
      arrival: {__typename?: "DateRange"; min?: any | null; max?: any | null};
      parks: Array<{__typename?: "Park"; id: number; name?: string | null}>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
    };
  };
};

export type CancelWatchMutationVariables = Exact<{
  watchId: Scalars["Int"]["input"];
}>;

export type CancelWatchMutation = {
  __typename?: "Mutation";
  watches: {
    __typename?: "WatchesMutation";
    cancel: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      consecutiveDays?: number | null;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "WatchFilter";
        isGroup?: boolean | null;
        petsAllowed?: boolean | null;
        adaAccessible?: boolean | null;
        isEquipmentMandatory?: boolean | null;
        maxVehicleLengthFeet?: number | null;
        maxVehicles?: number | null;
        people: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        };
      };
      arrival: {__typename?: "DateRange"; min?: any | null; max?: any | null};
      parks: Array<{__typename?: "Park"; id: number; name?: string | null}>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
    };
  };
};

export type MapSearchQueryVariables = Exact<{
  term?: InputMaybe<Scalars["String"]["input"]>;
  xmin?: InputMaybe<Scalars["Float"]["input"]>;
  ymin?: InputMaybe<Scalars["Float"]["input"]>;
  xmax?: InputMaybe<Scalars["Float"]["input"]>;
  ymax?: InputMaybe<Scalars["Float"]["input"]>;
  zoom?: InputMaybe<Scalars["Float"]["input"]>;
  beginDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  nights?: InputMaybe<Scalars["Int"]["input"]>;
  people?: InputMaybe<Scalars["Int"]["input"]>;
  strict?: InputMaybe<Scalars["Boolean"]["input"]>;
  firesAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  accessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<SortDirection>;
  cluster?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type MapSearchQuery = {
  __typename?: "Query";
  search: {
    __typename?: "SearchQuery";
    search: {
      __typename?: "SearchResults";
      results: Array<
        | {
            __typename?: "MapCluster";
            name?: string | null;
            count: number;
            isMatch: boolean;
            isShared: boolean;
            isWatched: boolean;
            expansionZoom: number;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
          }
        | {
            __typename?: "SearchResult";
            type: string;
            id: string;
            name?: string | null;
            isMatch: boolean;
            isShared: boolean;
            isWatched: boolean;
            updatedAt?: any | null;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
          }
      >;
      pagination: {
        __typename?: "PaginationResults";
        page: number;
        pageSize: number;
        totalResults: number;
      };
    };
  };
};

export type PickerSearchQueryVariables = Exact<{
  term?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PickerSearchQuery = {
  __typename?: "Query";
  search: {
    __typename?: "SearchQuery";
    search: {
      __typename?: "SearchResults";
      results: Array<
        | {__typename?: "MapCluster"}
        | {
            __typename?: "SearchResult";
            type: string;
            id: string;
            name?: string | null;
            isMatch: boolean;
            result?:
              | {
                  __typename?: "Facility";
                  park?: {__typename?: "Park"; name?: string | null} | null;
                }
              | {__typename?: "Park"}
              | {__typename?: "Place"}
              | {
                  __typename?: "Unit";
                  facility?: {
                    __typename?: "Facility";
                    name?: string | null;
                  } | null;
                }
              | null;
          }
      >;
    };
  };
};

export type GetOrganizationDetailsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetOrganizationDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    organization?: {
      __typename?: "Organization";
      id: number;
      name: string;
      url?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
    } | null;
  };
};

export type GetParkDetailsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetParkDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    park?: {
      __typename?: "Park";
      id: number;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      polygonGeoJson?: string | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
    } | null;
  };
};

export type GetFacilityDetailsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetFacilityDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    facility?: {
      __typename?: "Facility";
      id: number;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      lastCheckedAt?: any | null;
      park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    } | null;
  };
};

export type GetFacilityDetailsWithSlotsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
}>;

export type GetFacilityDetailsWithSlotsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    facility?: {
      __typename?: "Facility";
      id: number;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      lastCheckedAt?: any | null;
      park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        favoritesCount?: number | null;
        isEquipmentMandatory?: boolean | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
        slots: Array<{
          __typename?: "Slot";
          id?: number | null;
          slotDate?: any | null;
          status?: SlotStatus | null;
          version: number;
          updatedAt: any;
        }>;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
        allowedEquipment: Array<{
          __typename?: "Equipment";
          id: number;
          name: string;
          description?: string | null;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    } | null;
  };
};

export type GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  term?: InputMaybe<Scalars["String"]["input"]>;
  xmin?: InputMaybe<Scalars["Float"]["input"]>;
  ymin?: InputMaybe<Scalars["Float"]["input"]>;
  xmax?: InputMaybe<Scalars["Float"]["input"]>;
  ymax?: InputMaybe<Scalars["Float"]["input"]>;
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
  nights?: InputMaybe<Scalars["Int"]["input"]>;
  people?: InputMaybe<Scalars["Int"]["input"]>;
  strict?: InputMaybe<Scalars["Boolean"]["input"]>;
  firesAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  accessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeFCFS?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeUnreleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type GetFacilityDetailsWithFilteredUnitsAndSlotsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    facility?: {
      __typename?: "Facility";
      id: number;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      lastCheckedAt?: any | null;
      park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        favoritesCount?: number | null;
        isEquipmentMandatory?: boolean | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
        slots: Array<{
          __typename?: "Slot";
          id?: number | null;
          slotDate?: any | null;
          status?: SlotStatus | null;
          version: number;
          updatedAt: any;
        }>;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
        allowedEquipment: Array<{
          __typename?: "Equipment";
          id: number;
          name: string;
          description?: string | null;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    } | null;
  };
};

export type GetUnitDetailsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetUnitDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    unit?: {
      __typename?: "Unit";
      id: number;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      facility?: {
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      } | null;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    } | null;
  };
};

export type GetUnitDetailsWithSlotsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
}>;

export type GetUnitDetailsWithSlotsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    unit?: {
      __typename?: "Unit";
      id: number;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      slots: Array<{
        __typename?: "Slot";
        id?: number | null;
        slotDate?: any | null;
        status?: SlotStatus | null;
        version: number;
        updatedAt: any;
      }>;
      facility?: {
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      } | null;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
    } | null;
  };
};

export type GetCurrentUserQueryVariables = Exact<{[key: string]: never}>;

export type GetCurrentUserQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    currentUser?: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      settingsJson?: string | null;
      maxWatchCredits: number;
      usedWatchCredits: number;
      permissions?: Array<Permissions> | null;
      version?: number | null;
      isActive?: boolean | null;
      updatedAt?: any | null;
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    } | null;
  };
};

export type GetMaxDateQueryVariables = Exact<{[key: string]: never}>;

export type GetMaxDateQuery = {
  __typename?: "Query";
  search: {__typename?: "SearchQuery"; maxDate?: any | null};
};

export type GetLocationQueryVariables = Exact<{[key: string]: never}>;

export type GetLocationQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  };
};

export type GetFeatureFlagQueryVariables = Exact<{
  flag: Scalars["String"]["input"];
}>;

export type GetFeatureFlagQuery = {
  __typename?: "Query";
  features: {__typename?: "FeaturesQuery"; isEnabled: boolean};
};

export type GetCheckoutConfigQueryVariables = Exact<{[key: string]: never}>;

export type GetCheckoutConfigQuery = {
  __typename?: "Query";
  subscriptions: {
    __typename?: "SubscriptionsQuery";
    checkoutConfig: {
      __typename?: "CheckoutConfig";
      publishableKey: string;
      plans: Array<{
        __typename?: "SubscriptionPlan";
        id: string;
        name?: string | null;
        description?: string | null;
        amount: number;
        currency: string;
        interval: string;
      }>;
    };
  };
};

export type GetWatchListQueryVariables = Exact<{
  status?: InputMaybe<WatchStatus>;
}>;

export type GetWatchListQuery = {
  __typename?: "Query";
  watches: {
    __typename?: "WatchesQuery";
    watchList: Array<{
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      consecutiveDays?: number | null;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "WatchFilter";
        isGroup?: boolean | null;
        petsAllowed?: boolean | null;
        adaAccessible?: boolean | null;
        isEquipmentMandatory?: boolean | null;
        maxVehicleLengthFeet?: number | null;
        maxVehicles?: number | null;
        people: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        };
      };
      arrival: {__typename?: "DateRange"; min?: any | null; max?: any | null};
      parks: Array<{__typename?: "Park"; id: number; name?: string | null}>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
    }>;
  };
};

export type GetWatchQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetWatchQuery = {
  __typename?: "Query";
  watches: {
    __typename?: "WatchesQuery";
    watch: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      consecutiveDays?: number | null;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "WatchFilter";
        isGroup?: boolean | null;
        petsAllowed?: boolean | null;
        adaAccessible?: boolean | null;
        isEquipmentMandatory?: boolean | null;
        maxVehicleLengthFeet?: number | null;
        maxVehicles?: number | null;
        people: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        };
      };
      arrival: {__typename?: "DateRange"; min?: any | null; max?: any | null};
      parks: Array<{__typename?: "Park"; id: number; name?: string | null}>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {__typename?: "Park"; id: number; name?: string | null} | null;
      }>;
    };
  };
};

export type GetWatchFoundQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
}>;

export type GetWatchFoundQuery = {
  __typename?: "Query";
  watches: {
    __typename?: "WatchesQuery";
    watch: {
      __typename?: "Watch";
      id: number;
      foundUnits: Array<{
        __typename?: "Unit";
        id: number;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        favoritesCount?: number | null;
        isEquipmentMandatory?: boolean | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
        slots: Array<{
          __typename?: "Slot";
          id?: number | null;
          slotDate?: any | null;
          status?: SlotStatus | null;
          version: number;
          updatedAt: any;
        }>;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          name?: string | null;
          park?: {__typename?: "Park"; id: number; name?: string | null} | null;
        } | null;
        allowedEquipment: Array<{
          __typename?: "Equipment";
          id: number;
          name: string;
          description?: string | null;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
    };
  };
};

export type SearchPlacesQueryVariables = Exact<{
  term: Scalars["String"]["input"];
  near?: InputMaybe<LocationInput>;
  sessionToken?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchPlacesQuery = {
  __typename?: "Query";
  places: {
    __typename?: "PlacesQuery";
    search: {
      __typename?: "PlacesResults";
      sessionToken?: string | null;
      results: Array<{
        __typename?: "Place";
        id: string;
        name?: string | null;
        region?: string | null;
        placeType: string;
      }>;
    };
  };
};

export type GetPlaceLocationQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  sessionToken?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetPlaceLocationQuery = {
  __typename?: "Query";
  places: {
    __typename?: "PlacesQuery";
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  };
};

export type GetRecentlyCheckedFacilitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRecentlyCheckedFacilitiesQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    recentlyCheckedFacilities: Array<{
      __typename?: "Facility";
      id: number;
      name?: string | null;
      lastCheckedAt?: any | null;
      park?: {__typename?: "Park"; id: number; name?: string | null} | null;
    }>;
  };
};

export type CalculateUsedCreditsQueryVariables = Exact<{
  parkIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  facilityIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  unitIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type CalculateUsedCreditsQuery = {
  __typename?: "Query";
  subscriptions: {
    __typename?: "SubscriptionsQuery";
    calculateUsedCredits: number;
  };
};

export type CountSubscriptionVariables = Exact<{
  target?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CountSubscription = {__typename?: "Subscription"; count: number};

export type ChatMessagesSubscriptionVariables = Exact<{[key: string]: never}>;

export type ChatMessagesSubscription = {
  __typename?: "Subscription";
  chatMessages: {
    __typename?: "ChatMessage";
    id?: string | null;
    message: string;
    sentAt: any;
    deletedAt?: any | null;
    sender?: {
      __typename?: "User";
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    } | null;
    recipient?: {
      __typename?: "User";
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    } | null;
  };
};

export const PaginationFieldsFragmentDoc = gql`
  fragment PaginationFields on PaginationResults {
    page
    pageSize
    totalResults
  }
`;
export const MediaMarkerFieldsFragmentDoc = gql`
  fragment MediaMarkerFields on Media {
    id
    title
    url
    width
    height
  }
`;
export const FacilityMarkerFieldsFragmentDoc = gql`
  fragment FacilityMarkerFields on Facility {
    location {
      latitude
      longitude
    }
    id
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    lastCheckedAt
  }
  ${MediaMarkerFieldsFragmentDoc}
`;
export const UnitMarkerFieldsFragmentDoc = gql`
  fragment UnitMarkerFields on Unit {
    location {
      latitude
      longitude
    }
    id
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    facility {
      ...FacilityMarkerFields
    }
  }
  ${MediaMarkerFieldsFragmentDoc}
  ${FacilityMarkerFieldsFragmentDoc}
`;
export const EquipmentFieldsFragmentDoc = gql`
  fragment EquipmentFields on Equipment {
    id
    name
    description
    isActive
    updatedAt
  }
`;
export const ClosureFieldsFragmentDoc = gql`
  fragment ClosureFields on Closure {
    id
    beginsAt
    endsAt
    reason
    version
    isActive
    updatedAt
  }
`;
export const MediaFieldsFragmentDoc = gql`
  fragment MediaFields on Media {
    id
    type
    title
    subtitle
    description
    credits
    url
    width
    height
    version
    isActive
    updatedAt
  }
`;
export const UnitFieldsFragmentDoc = gql`
  fragment UnitFields on Unit {
    id
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    maxVehicles
    maxVehicleLengthFeet
    favoritesCount
    isEquipmentMandatory
    facility {
      id
      name
      park {
        id
        name
      }
    }
    allowedEquipment {
      ...EquipmentFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    version
    isActive
    updatedAt
  }
  ${EquipmentFieldsFragmentDoc}
  ${ClosureFieldsFragmentDoc}
  ${MediaFieldsFragmentDoc}
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    type
    street
    city
    state
    zip
    country
    version
    isActive
    updatedAt
  }
`;
export const FacilityCommonFieldsFragmentDoc = gql`
  fragment FacilityCommonFields on Facility {
    id
    type
    name
    url
    bookingUrl
    reservationUrl
    mapUrl
    checkinHour
    checkoutHour
    description
    descriptionHtml
    feeDescription
    rules
    directions
    stayLimit
    phone
    email
    isReservable
    isAdaAccessible
    location {
      latitude
      longitude
    }
    favoritesCount
    unitCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    isWatched
    isShared
    version
    isActive
    updatedAt
    lastCheckedAt
  }
  ${AddressFieldsFragmentDoc}
  ${ClosureFieldsFragmentDoc}
  ${MediaFieldsFragmentDoc}
`;
export const FacilityUnitFieldsFragmentDoc = gql`
  fragment FacilityUnitFields on Unit {
    id
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    maxVehicles
    maxVehicleLengthFeet
    isEquipmentMandatory
    primaryMedia {
      ...MediaFields
    }
  }
  ${MediaFieldsFragmentDoc}
`;
export const FacilityFieldsFragmentDoc = gql`
  fragment FacilityFields on Facility {
    ...FacilityCommonFields
    units {
      ...FacilityUnitFields
    }
  }
  ${FacilityCommonFieldsFragmentDoc}
  ${FacilityUnitFieldsFragmentDoc}
`;
export const OrganizationFieldsFragmentDoc = gql`
  fragment OrganizationFields on Organization {
    id
    name
    url
    addresses {
      ...AddressFields
    }
    version
    isActive
    updatedAt
  }
  ${AddressFieldsFragmentDoc}
`;
export const ParkMarkerFieldsFragmentDoc = gql`
  fragment ParkMarkerFields on Park {
    location {
      latitude
      longitude
    }
    id
    name
    primaryMedia {
      ...MediaMarkerFields
    }
  }
  ${MediaMarkerFieldsFragmentDoc}
`;
export const FacilityWithoutUnitsFieldsFragmentDoc = gql`
  fragment FacilityWithoutUnitsFields on Facility {
    ...FacilityCommonFields
    unitCount
  }
  ${FacilityCommonFieldsFragmentDoc}
`;
export const ParkFieldsFragmentDoc = gql`
  fragment ParkFields on Park {
    id
    name
    description
    descriptionHtml
    feeDescription
    directions
    stayLimit
    phone
    email
    reservationUrl
    mapUrl
    isReservable
    location {
      latitude
      longitude
    }
    polygonGeoJson
    favoritesCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    facilities {
      ...FacilityWithoutUnitsFields
    }
    version
    isActive
    updatedAt
  }
  ${AddressFieldsFragmentDoc}
  ${ClosureFieldsFragmentDoc}
  ${MediaFieldsFragmentDoc}
  ${FacilityWithoutUnitsFieldsFragmentDoc}
`;
export const SlotFieldsFragmentDoc = gql`
  fragment SlotFields on Slot {
    id
    slotDate
    status
    version
    updatedAt
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    displayName
    avatarImageUrl
  }
`;
export const AuthenticatedUserFieldsFragmentDoc = gql`
  fragment AuthenticatedUserFields on User {
    ...UserFields
    firstName
    lastName
    email
    settingsJson
    maxWatchCredits
    usedWatchCredits
    permissions
    version
    isActive
    updatedAt
  }
  ${UserFieldsFragmentDoc}
`;
export const AdminUserFieldsFragmentDoc = gql`
  fragment AdminUserFields on User {
    roles
  }
`;
export const ChatMessageFieldsFragmentDoc = gql`
  fragment ChatMessageFields on ChatMessage {
    id
    sender {
      ...UserFields
    }
    recipient {
      ...UserFields
    }
    message
    sentAt
    deletedAt
  }
  ${UserFieldsFragmentDoc}
`;
export const WatchFieldsFragmentDoc = gql`
  fragment WatchFields on Watch {
    id
    name
    displayName
    creator {
      id
    }
    filter {
      people {
        min
        max
      }
      isGroup
      petsAllowed
      adaAccessible
      isEquipmentMandatory
      maxVehicleLengthFeet
      maxVehicles
    }
    arrival {
      min
      max
    }
    consecutiveDays
    scheduleDescription
    status
    expiresAt
    lastScannedAt
    alertsEnabled
    version
    createdAt
    updatedAt
    parks {
      id
      name
    }
    facilities {
      id
      name
      park {
        id
        name
      }
    }
    units {
      id
      name
      facility {
        id
        name
        park {
          id
          name
        }
      }
    }
    effectiveFacilities {
      id
      name
      park {
        id
        name
      }
    }
    foundFacilities {
      id
      name
      park {
        id
        name
      }
    }
  }
`;
export const PlaceFieldsFragmentDoc = gql`
  fragment PlaceFields on Place {
    id
    name
    region
    placeType
  }
`;
export const SignUpDocument = gql`
  mutation SignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $captchaResponse: String!
    $reason: String
    $referral: String
    $acceptedTerms: Boolean!
  ) {
    users {
      signUp(
        input: {
          email: $email
          firstName: $firstName
          lastName: $lastName
          captchaResponse: $captchaResponse
          reason: $reason
          referral: $referral
          acceptedTerms: $acceptedTerms
        }
      ) {
        ... on SignUpSuccessResult {
          userId
          email
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      captchaResponse: // value for 'captchaResponse'
 *      reason: // value for 'reason'
 *      referral: // value for 'referral'
 *      acceptedTerms: // value for 'acceptedTerms'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword(
    $token: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    users {
      resetPassword(
        input: {
          token: $token
          newPassword: $newPassword
          newPasswordConfirmation: $newPasswordConfirmation
        }
      ) {
        ... on ResetPasswordSuccessResult {
          success
          email
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPasswordRequestDocument = gql`
  mutation ResetPasswordRequest($email: String!, $captchaResponse: String!) {
    users {
      resetPasswordRequest(
        input: {email: $email, captchaResponse: $captchaResponse}
      ) {
        ... on ResetPasswordSuccessResult {
          success
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type ResetPasswordRequestMutationFn = Apollo.MutationFunction<
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables
>;

/**
 * __useResetPasswordRequestMutation__
 *
 * To run a mutation, you first call `useResetPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordRequestMutation, { data, loading, error }] = useResetPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captchaResponse: // value for 'captchaResponse'
 *   },
 * });
 */
export function useResetPasswordRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordRequestMutation,
    ResetPasswordRequestMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ResetPasswordRequestMutation,
    ResetPasswordRequestMutationVariables
  >(ResetPasswordRequestDocument, options);
}
export type ResetPasswordRequestMutationHookResult = ReturnType<
  typeof useResetPasswordRequestMutation
>;
export type ResetPasswordRequestMutationResult =
  Apollo.MutationResult<ResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings(
    $firstName: String
    $lastName: String
    $email: String
    $oldPassword: String
    $newPassword: String
    $partialSettingsJson: String
  ) {
    users {
      updateSettings(
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          oldPassword: $oldPassword
          newPassword: $newPassword
          partialSettingsJson: $partialSettingsJson
        }
      ) {
        ...AuthenticatedUserFields
      }
    }
  }
  ${AuthenticatedUserFieldsFragmentDoc}
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      partialSettingsJson: // value for 'partialSettingsJson'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UpdateUserSettingsDocument, options);
}
export type UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationResult =
  Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const UpdateUnitDocument = gql`
  mutation UpdateUnit(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $useType: String
    $name: String
    $loopName: String
    $location: LocationInput
    $peopleCapacity: IntRangeInput
    $rules: String
    $isGroup: Boolean
    $isReservable: Boolean
    $isAdaAccessible: Boolean
    $petsAllowed: Boolean
    $maxVehicles: Int
    $maxVehicleLengthFeet: Int
    $facilityId: Int
    $isEquipmentMandatory: Boolean
    $allowedEquipmentIds: [Int!]
    $isActive: Boolean
  ) {
    entities {
      updateUnit(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          useType: $useType
          name: $name
          loopName: $loopName
          location: $location
          peopleCapacity: $peopleCapacity
          rules: $rules
          isGroup: $isGroup
          isReservable: $isReservable
          isAdaAccessible: $isAdaAccessible
          petsAllowed: $petsAllowed
          maxVehicles: $maxVehicles
          maxVehicleLengthFeet: $maxVehicleLengthFeet
          facilityId: $facilityId
          isEquipmentMandatory: $isEquipmentMandatory
          allowedEquipmentIds: $allowedEquipmentIds
          isActive: $isActive
        }
      ) {
        ...UnitFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type UpdateUnitMutationFn = Apollo.MutationFunction<
  UpdateUnitMutation,
  UpdateUnitMutationVariables
>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      useType: // value for 'useType'
 *      name: // value for 'name'
 *      loopName: // value for 'loopName'
 *      location: // value for 'location'
 *      peopleCapacity: // value for 'peopleCapacity'
 *      rules: // value for 'rules'
 *      isGroup: // value for 'isGroup'
 *      isReservable: // value for 'isReservable'
 *      isAdaAccessible: // value for 'isAdaAccessible'
 *      petsAllowed: // value for 'petsAllowed'
 *      maxVehicles: // value for 'maxVehicles'
 *      maxVehicleLengthFeet: // value for 'maxVehicleLengthFeet'
 *      facilityId: // value for 'facilityId'
 *      isEquipmentMandatory: // value for 'isEquipmentMandatory'
 *      allowedEquipmentIds: // value for 'allowedEquipmentIds'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUnitMutation,
    UpdateUnitMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(
    UpdateUnitDocument,
    options
  );
}
export type UpdateUnitMutationHookResult = ReturnType<
  typeof useUpdateUnitMutation
>;
export type UpdateUnitMutationResult =
  Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateUnitMutation,
  UpdateUnitMutationVariables
>;
export const UpdateFacilityDocument = gql`
  mutation UpdateFacility(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $name: String
    $url: String
    $bookingUrl: String
    $reservationUrl: String
    $mapUrl: String
    $checkinHour: Int
    $checkoutHour: Int
    $description: String
    $descriptionHtml: String
    $feeDescription: String
    $rules: String
    $directions: String
    $stayLimit: String
    $phone: String
    $email: String
    $isReservable: Boolean
    $isAdaAccessible: Boolean
    $location: LocationInput
    $parkId: Int
    $organizationId: Int
    $isActive: Boolean
  ) {
    entities {
      updateFacility(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          name: $name
          url: $url
          bookingUrl: $bookingUrl
          reservationUrl: $reservationUrl
          mapUrl: $mapUrl
          checkinHour: $checkinHour
          checkoutHour: $checkoutHour
          description: $description
          descriptionHtml: $descriptionHtml
          feeDescription: $feeDescription
          rules: $rules
          directions: $directions
          stayLimit: $stayLimit
          phone: $phone
          email: $email
          isReservable: $isReservable
          isAdaAccessible: $isAdaAccessible
          location: $location
          parkId: $parkId
          organizationId: $organizationId
          isActive: $isActive
        }
      ) {
        ...FacilityFields
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
`;
export type UpdateFacilityMutationFn = Apollo.MutationFunction<
  UpdateFacilityMutation,
  UpdateFacilityMutationVariables
>;

/**
 * __useUpdateFacilityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityMutation, { data, loading, error }] = useUpdateFacilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      bookingUrl: // value for 'bookingUrl'
 *      reservationUrl: // value for 'reservationUrl'
 *      mapUrl: // value for 'mapUrl'
 *      checkinHour: // value for 'checkinHour'
 *      checkoutHour: // value for 'checkoutHour'
 *      description: // value for 'description'
 *      descriptionHtml: // value for 'descriptionHtml'
 *      feeDescription: // value for 'feeDescription'
 *      rules: // value for 'rules'
 *      directions: // value for 'directions'
 *      stayLimit: // value for 'stayLimit'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      isReservable: // value for 'isReservable'
 *      isAdaAccessible: // value for 'isAdaAccessible'
 *      location: // value for 'location'
 *      parkId: // value for 'parkId'
 *      organizationId: // value for 'organizationId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFacilityMutation,
    UpdateFacilityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateFacilityMutation,
    UpdateFacilityMutationVariables
  >(UpdateFacilityDocument, options);
}
export type UpdateFacilityMutationHookResult = ReturnType<
  typeof useUpdateFacilityMutation
>;
export type UpdateFacilityMutationResult =
  Apollo.MutationResult<UpdateFacilityMutation>;
export type UpdateFacilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateFacilityMutation,
  UpdateFacilityMutationVariables
>;
export const UpdateParkDocument = gql`
  mutation UpdatePark(
    $id: Int!
    $unsetFields: [String!]
    $name: String
    $description: String
    $descriptionHtml: String
    $feeDescription: String
    $directions: String
    $stayLimit: String
    $phone: String
    $email: String
    $reservationUrl: String
    $mapUrl: String
    $isReservable: Boolean
    $location: LocationInput
    $organizationId: Int
    $isActive: Boolean
  ) {
    entities {
      updatePark(
        input: {
          id: $id
          unsetFields: $unsetFields
          name: $name
          description: $description
          descriptionHtml: $descriptionHtml
          feeDescription: $feeDescription
          directions: $directions
          stayLimit: $stayLimit
          phone: $phone
          email: $email
          reservationUrl: $reservationUrl
          mapUrl: $mapUrl
          isReservable: $isReservable
          location: $location
          organizationId: $organizationId
          isActive: $isActive
        }
      ) {
        ...ParkFields
      }
    }
  }
  ${ParkFieldsFragmentDoc}
`;
export type UpdateParkMutationFn = Apollo.MutationFunction<
  UpdateParkMutation,
  UpdateParkMutationVariables
>;

/**
 * __useUpdateParkMutation__
 *
 * To run a mutation, you first call `useUpdateParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParkMutation, { data, loading, error }] = useUpdateParkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      descriptionHtml: // value for 'descriptionHtml'
 *      feeDescription: // value for 'feeDescription'
 *      directions: // value for 'directions'
 *      stayLimit: // value for 'stayLimit'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      reservationUrl: // value for 'reservationUrl'
 *      mapUrl: // value for 'mapUrl'
 *      isReservable: // value for 'isReservable'
 *      location: // value for 'location'
 *      organizationId: // value for 'organizationId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateParkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParkMutation,
    UpdateParkMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateParkMutation, UpdateParkMutationVariables>(
    UpdateParkDocument,
    options
  );
}
export type UpdateParkMutationHookResult = ReturnType<
  typeof useUpdateParkMutation
>;
export type UpdateParkMutationResult =
  Apollo.MutationResult<UpdateParkMutation>;
export type UpdateParkMutationOptions = Apollo.BaseMutationOptions<
  UpdateParkMutation,
  UpdateParkMutationVariables
>;
export const UpdateMediaDocument = gql`
  mutation UpdateMedia(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $title: String
    $subtitle: String
    $description: String
    $credits: String
    $url: String
    $width: Int
    $height: Int
    $isActive: Boolean
  ) {
    entities {
      updateMedia(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          title: $title
          subtitle: $subtitle
          description: $description
          credits: $credits
          url: $url
          width: $width
          height: $height
          isActive: $isActive
        }
      ) {
        ...MediaFields
      }
    }
  }
  ${MediaFieldsFragmentDoc}
`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      description: // value for 'description'
 *      credits: // value for 'credits'
 *      url: // value for 'url'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaMutation,
    UpdateMediaMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(
    UpdateMediaDocument,
    options
  );
}
export type UpdateMediaMutationHookResult = ReturnType<
  typeof useUpdateMediaMutation
>;
export type UpdateMediaMutationResult =
  Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $street: [String!]
    $city: String
    $state: String
    $zip: String
    $country: String
    $isActive: Boolean
  ) {
    entities {
      updateAddress(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          street: $street
          city: $city
          state: $state
          zip: $zip
          country: $country
          isActive: $isActive
        }
      ) {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      street: // value for 'street'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      country: // value for 'country'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
  typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
  Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const UpdateClosureDocument = gql`
  mutation UpdateClosure(
    $id: Int!
    $unsetFields: [String!]
    $beginsAt: DateTime
    $endsAt: DateTime
    $reason: String
    $isActive: Boolean
  ) {
    entities {
      updateClosure(
        input: {
          id: $id
          unsetFields: $unsetFields
          beginsAt: $beginsAt
          endsAt: $endsAt
          reason: $reason
          isActive: $isActive
        }
      ) {
        ...ClosureFields
      }
    }
  }
  ${ClosureFieldsFragmentDoc}
`;
export type UpdateClosureMutationFn = Apollo.MutationFunction<
  UpdateClosureMutation,
  UpdateClosureMutationVariables
>;

/**
 * __useUpdateClosureMutation__
 *
 * To run a mutation, you first call `useUpdateClosureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClosureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClosureMutation, { data, loading, error }] = useUpdateClosureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      beginsAt: // value for 'beginsAt'
 *      endsAt: // value for 'endsAt'
 *      reason: // value for 'reason'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateClosureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClosureMutation,
    UpdateClosureMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClosureMutation,
    UpdateClosureMutationVariables
  >(UpdateClosureDocument, options);
}
export type UpdateClosureMutationHookResult = ReturnType<
  typeof useUpdateClosureMutation
>;
export type UpdateClosureMutationResult =
  Apollo.MutationResult<UpdateClosureMutation>;
export type UpdateClosureMutationOptions = Apollo.BaseMutationOptions<
  UpdateClosureMutation,
  UpdateClosureMutationVariables
>;
export const RefreshUnitDocument = gql`
  mutation RefreshUnit($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshUnit(unitId: $id, clearOverrides: $clearOverrides) {
        ...UnitFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type RefreshUnitMutationFn = Apollo.MutationFunction<
  RefreshUnitMutation,
  RefreshUnitMutationVariables
>;

/**
 * __useRefreshUnitMutation__
 *
 * To run a mutation, you first call `useRefreshUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUnitMutation, { data, loading, error }] = useRefreshUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshUnitMutation,
    RefreshUnitMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RefreshUnitMutation, RefreshUnitMutationVariables>(
    RefreshUnitDocument,
    options
  );
}
export type RefreshUnitMutationHookResult = ReturnType<
  typeof useRefreshUnitMutation
>;
export type RefreshUnitMutationResult =
  Apollo.MutationResult<RefreshUnitMutation>;
export type RefreshUnitMutationOptions = Apollo.BaseMutationOptions<
  RefreshUnitMutation,
  RefreshUnitMutationVariables
>;
export const RefreshFacilityDocument = gql`
  mutation RefreshFacility($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshFacility(facilityId: $id, clearOverrides: $clearOverrides) {
        ...FacilityFields
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
`;
export type RefreshFacilityMutationFn = Apollo.MutationFunction<
  RefreshFacilityMutation,
  RefreshFacilityMutationVariables
>;

/**
 * __useRefreshFacilityMutation__
 *
 * To run a mutation, you first call `useRefreshFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshFacilityMutation, { data, loading, error }] = useRefreshFacilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshFacilityMutation,
    RefreshFacilityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshFacilityMutation,
    RefreshFacilityMutationVariables
  >(RefreshFacilityDocument, options);
}
export type RefreshFacilityMutationHookResult = ReturnType<
  typeof useRefreshFacilityMutation
>;
export type RefreshFacilityMutationResult =
  Apollo.MutationResult<RefreshFacilityMutation>;
export type RefreshFacilityMutationOptions = Apollo.BaseMutationOptions<
  RefreshFacilityMutation,
  RefreshFacilityMutationVariables
>;
export const RefreshParkDocument = gql`
  mutation RefreshPark($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshPark(parkId: $id, clearOverrides: $clearOverrides) {
        ...ParkFields
      }
    }
  }
  ${ParkFieldsFragmentDoc}
`;
export type RefreshParkMutationFn = Apollo.MutationFunction<
  RefreshParkMutation,
  RefreshParkMutationVariables
>;

/**
 * __useRefreshParkMutation__
 *
 * To run a mutation, you first call `useRefreshParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshParkMutation, { data, loading, error }] = useRefreshParkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshParkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshParkMutation,
    RefreshParkMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RefreshParkMutation, RefreshParkMutationVariables>(
    RefreshParkDocument,
    options
  );
}
export type RefreshParkMutationHookResult = ReturnType<
  typeof useRefreshParkMutation
>;
export type RefreshParkMutationResult =
  Apollo.MutationResult<RefreshParkMutation>;
export type RefreshParkMutationOptions = Apollo.BaseMutationOptions<
  RefreshParkMutation,
  RefreshParkMutationVariables
>;
export const RefreshMediaDocument = gql`
  mutation RefreshMedia($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshMedia(mediaId: $id, clearOverrides: $clearOverrides) {
        ...MediaFields
      }
    }
  }
  ${MediaFieldsFragmentDoc}
`;
export type RefreshMediaMutationFn = Apollo.MutationFunction<
  RefreshMediaMutation,
  RefreshMediaMutationVariables
>;

/**
 * __useRefreshMediaMutation__
 *
 * To run a mutation, you first call `useRefreshMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMediaMutation, { data, loading, error }] = useRefreshMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMediaMutation,
    RefreshMediaMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshMediaMutation,
    RefreshMediaMutationVariables
  >(RefreshMediaDocument, options);
}
export type RefreshMediaMutationHookResult = ReturnType<
  typeof useRefreshMediaMutation
>;
export type RefreshMediaMutationResult =
  Apollo.MutationResult<RefreshMediaMutation>;
export type RefreshMediaMutationOptions = Apollo.BaseMutationOptions<
  RefreshMediaMutation,
  RefreshMediaMutationVariables
>;
export const RefreshAddressDocument = gql`
  mutation RefreshAddress($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshAddress(addressId: $id, clearOverrides: $clearOverrides) {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type RefreshAddressMutationFn = Apollo.MutationFunction<
  RefreshAddressMutation,
  RefreshAddressMutationVariables
>;

/**
 * __useRefreshAddressMutation__
 *
 * To run a mutation, you first call `useRefreshAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAddressMutation, { data, loading, error }] = useRefreshAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAddressMutation,
    RefreshAddressMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshAddressMutation,
    RefreshAddressMutationVariables
  >(RefreshAddressDocument, options);
}
export type RefreshAddressMutationHookResult = ReturnType<
  typeof useRefreshAddressMutation
>;
export type RefreshAddressMutationResult =
  Apollo.MutationResult<RefreshAddressMutation>;
export type RefreshAddressMutationOptions = Apollo.BaseMutationOptions<
  RefreshAddressMutation,
  RefreshAddressMutationVariables
>;
export const RefreshClosureDocument = gql`
  mutation RefreshClosure($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshClosure(closureId: $id, clearOverrides: $clearOverrides) {
        ...ClosureFields
      }
    }
  }
  ${ClosureFieldsFragmentDoc}
`;
export type RefreshClosureMutationFn = Apollo.MutationFunction<
  RefreshClosureMutation,
  RefreshClosureMutationVariables
>;

/**
 * __useRefreshClosureMutation__
 *
 * To run a mutation, you first call `useRefreshClosureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshClosureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshClosureMutation, { data, loading, error }] = useRefreshClosureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshClosureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshClosureMutation,
    RefreshClosureMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshClosureMutation,
    RefreshClosureMutationVariables
  >(RefreshClosureDocument, options);
}
export type RefreshClosureMutationHookResult = ReturnType<
  typeof useRefreshClosureMutation
>;
export type RefreshClosureMutationResult =
  Apollo.MutationResult<RefreshClosureMutation>;
export type RefreshClosureMutationOptions = Apollo.BaseMutationOptions<
  RefreshClosureMutation,
  RefreshClosureMutationVariables
>;
export const SendChatMessageDocument = gql`
  mutation SendChatMessage($message: String!) {
    chat {
      sendMessage(input: {message: $message}) {
        ...ChatMessageFields
      }
    }
  }
  ${ChatMessageFieldsFragmentDoc}
`;
export type SendChatMessageMutationFn = Apollo.MutationFunction<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >(SendChatMessageDocument, options);
}
export type SendChatMessageMutationHookResult = ReturnType<
  typeof useSendChatMessageMutation
>;
export type SendChatMessageMutationResult =
  Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;
export const UploadMappingDocument = gql`
  mutation UploadMapping($name: String!, $file: Upload!) {
    uploads {
      uploadMapping(name: $name, file: $file) {
        uploadId
        fileName
        fileFormat
        rows {
          id
          name
          description
          location {
            latitude
            longitude
          }
          unit {
            id
            name
            loopName
            facility {
              id
              name
              park {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type UploadMappingMutationFn = Apollo.MutationFunction<
  UploadMappingMutation,
  UploadMappingMutationVariables
>;

/**
 * __useUploadMappingMutation__
 *
 * To run a mutation, you first call `useUploadMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMappingMutation, { data, loading, error }] = useUploadMappingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadMappingMutation,
    UploadMappingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UploadMappingMutation,
    UploadMappingMutationVariables
  >(UploadMappingDocument, options);
}
export type UploadMappingMutationHookResult = ReturnType<
  typeof useUploadMappingMutation
>;
export type UploadMappingMutationResult =
  Apollo.MutationResult<UploadMappingMutation>;
export type UploadMappingMutationOptions = Apollo.BaseMutationOptions<
  UploadMappingMutation,
  UploadMappingMutationVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation CreateSubscription($planId: String!) {
    subscriptions {
      create(planId: $planId) {
        subscriptionId
        clientSecret
      }
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CreateSubscriptionDocument, options);
}
export type CreateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateSubscriptionMutation
>;
export type CreateSubscriptionMutationResult =
  Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($subscriptionId: String!) {
    subscriptions {
      cancel(subscriptionId: $subscriptionId)
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const CreateWatchDocument = gql`
  mutation CreateWatch(
    $name: String
    $isGroup: Boolean
    $minPeople: Int
    $maxPeople: Int
    $petsAllowed: Boolean
    $adaAccessible: Boolean
    $isEquipmentMandatory: Boolean
    $maxVehicleLengthFeet: Int
    $maxVehicles: Int
    $minArrival: Date!
    $maxArrival: Date!
    $consecutiveDays: Int
    $alertsEnabled: Boolean!
    $parkIds: [Int!]
    $facilityIds: [Int!]
    $unitIds: [Int!]
  ) {
    watches {
      create(
        input: {
          name: $name
          filter: {
            isGroup: $isGroup
            people: {min: $minPeople, max: $maxPeople}
            petsAllowed: $petsAllowed
            adaAccessible: $adaAccessible
            isEquipmentMandatory: $isEquipmentMandatory
            maxVehicleLengthFeet: $maxVehicleLengthFeet
            maxVehicles: $maxVehicles
          }
          arrival: {min: $minArrival, max: $maxArrival}
          consecutiveDays: $consecutiveDays
          alertsEnabled: $alertsEnabled
          parkIds: $parkIds
          facilityIds: $facilityIds
          unitIds: $unitIds
        }
      ) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;
export type CreateWatchMutationFn = Apollo.MutationFunction<
  CreateWatchMutation,
  CreateWatchMutationVariables
>;

/**
 * __useCreateWatchMutation__
 *
 * To run a mutation, you first call `useCreateWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWatchMutation, { data, loading, error }] = useCreateWatchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isGroup: // value for 'isGroup'
 *      minPeople: // value for 'minPeople'
 *      maxPeople: // value for 'maxPeople'
 *      petsAllowed: // value for 'petsAllowed'
 *      adaAccessible: // value for 'adaAccessible'
 *      isEquipmentMandatory: // value for 'isEquipmentMandatory'
 *      maxVehicleLengthFeet: // value for 'maxVehicleLengthFeet'
 *      maxVehicles: // value for 'maxVehicles'
 *      minArrival: // value for 'minArrival'
 *      maxArrival: // value for 'maxArrival'
 *      consecutiveDays: // value for 'consecutiveDays'
 *      alertsEnabled: // value for 'alertsEnabled'
 *      parkIds: // value for 'parkIds'
 *      facilityIds: // value for 'facilityIds'
 *      unitIds: // value for 'unitIds'
 *   },
 * });
 */
export function useCreateWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWatchMutation,
    CreateWatchMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateWatchMutation, CreateWatchMutationVariables>(
    CreateWatchDocument,
    options
  );
}
export type CreateWatchMutationHookResult = ReturnType<
  typeof useCreateWatchMutation
>;
export type CreateWatchMutationResult =
  Apollo.MutationResult<CreateWatchMutation>;
export type CreateWatchMutationOptions = Apollo.BaseMutationOptions<
  CreateWatchMutation,
  CreateWatchMutationVariables
>;
export const UpdateWatchDocument = gql`
  mutation UpdateWatch(
    $watchId: Int!
    $name: String
    $unsetFields: [String!]
    $isGroup: Boolean
    $minPeople: Int
    $maxPeople: Int
    $petsAllowed: Boolean
    $adaAccessible: Boolean
    $isEquipmentMandatory: Boolean
    $maxVehicleLengthFeet: Int
    $maxVehicles: Int
    $minArrival: Date
    $maxArrival: Date
    $consecutiveDays: Int
    $alertsEnabled: Boolean
    $parkIds: [Int!]
    $facilityIds: [Int!]
    $unitIds: [Int!]
  ) {
    watches {
      update(
        watchId: $watchId
        unsetFields: $unsetFields
        input: {
          name: $name
          filter: {
            isGroup: $isGroup
            people: {min: $minPeople, max: $maxPeople}
            petsAllowed: $petsAllowed
            adaAccessible: $adaAccessible
            isEquipmentMandatory: $isEquipmentMandatory
            maxVehicleLengthFeet: $maxVehicleLengthFeet
            maxVehicles: $maxVehicles
          }
          arrival: {min: $minArrival, max: $maxArrival}
          consecutiveDays: $consecutiveDays
          alertsEnabled: $alertsEnabled
          parkIds: $parkIds
          facilityIds: $facilityIds
          unitIds: $unitIds
        }
      ) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;
export type UpdateWatchMutationFn = Apollo.MutationFunction<
  UpdateWatchMutation,
  UpdateWatchMutationVariables
>;

/**
 * __useUpdateWatchMutation__
 *
 * To run a mutation, you first call `useUpdateWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWatchMutation, { data, loading, error }] = useUpdateWatchMutation({
 *   variables: {
 *      watchId: // value for 'watchId'
 *      name: // value for 'name'
 *      unsetFields: // value for 'unsetFields'
 *      isGroup: // value for 'isGroup'
 *      minPeople: // value for 'minPeople'
 *      maxPeople: // value for 'maxPeople'
 *      petsAllowed: // value for 'petsAllowed'
 *      adaAccessible: // value for 'adaAccessible'
 *      isEquipmentMandatory: // value for 'isEquipmentMandatory'
 *      maxVehicleLengthFeet: // value for 'maxVehicleLengthFeet'
 *      maxVehicles: // value for 'maxVehicles'
 *      minArrival: // value for 'minArrival'
 *      maxArrival: // value for 'maxArrival'
 *      consecutiveDays: // value for 'consecutiveDays'
 *      alertsEnabled: // value for 'alertsEnabled'
 *      parkIds: // value for 'parkIds'
 *      facilityIds: // value for 'facilityIds'
 *      unitIds: // value for 'unitIds'
 *   },
 * });
 */
export function useUpdateWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWatchMutation,
    UpdateWatchMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateWatchMutation, UpdateWatchMutationVariables>(
    UpdateWatchDocument,
    options
  );
}
export type UpdateWatchMutationHookResult = ReturnType<
  typeof useUpdateWatchMutation
>;
export type UpdateWatchMutationResult =
  Apollo.MutationResult<UpdateWatchMutation>;
export type UpdateWatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateWatchMutation,
  UpdateWatchMutationVariables
>;
export const CancelWatchDocument = gql`
  mutation CancelWatch($watchId: Int!) {
    watches {
      cancel(watchId: $watchId) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;
export type CancelWatchMutationFn = Apollo.MutationFunction<
  CancelWatchMutation,
  CancelWatchMutationVariables
>;

/**
 * __useCancelWatchMutation__
 *
 * To run a mutation, you first call `useCancelWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWatchMutation, { data, loading, error }] = useCancelWatchMutation({
 *   variables: {
 *      watchId: // value for 'watchId'
 *   },
 * });
 */
export function useCancelWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWatchMutation,
    CancelWatchMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CancelWatchMutation, CancelWatchMutationVariables>(
    CancelWatchDocument,
    options
  );
}
export type CancelWatchMutationHookResult = ReturnType<
  typeof useCancelWatchMutation
>;
export type CancelWatchMutationResult =
  Apollo.MutationResult<CancelWatchMutation>;
export type CancelWatchMutationOptions = Apollo.BaseMutationOptions<
  CancelWatchMutation,
  CancelWatchMutationVariables
>;
export const MapSearchDocument = gql`
  query MapSearch(
    $term: String
    $xmin: Float
    $ymin: Float
    $xmax: Float
    $ymax: Float
    $zoom: Float
    $beginDate: Date
    $endDate: Date
    $nights: Int
    $people: Int
    $strict: Boolean
    $firesAllowed: Boolean
    $petsAllowed: Boolean
    $accessible: Boolean
    $excludeGroup: Boolean
    $onlyMatches: Boolean
    $page: Int = 1
    $pageSize: Int = 100
    $sortField: String
    $sortDirection: SortDirection
    $cluster: Boolean = false
  ) {
    search {
      search(
        input: {
          term: $term
          boundingBox: {xmin: $xmin, ymin: $ymin, xmax: $xmax, ymax: $ymax}
          zoom: $zoom
          beginDate: $beginDate
          endDate: $endDate
          nights: $nights
          people: $people
          strict: $strict
          firesAllowed: $firesAllowed
          petsAllowed: $petsAllowed
          accessible: $accessible
          excludeGroup: $excludeGroup
          onlyMatches: $onlyMatches
          pagination: {page: $page, pageSize: $pageSize}
          sort: {field: $sortField, direction: $sortDirection}
          cluster: $cluster
        }
      ) {
        results {
          ... on SearchResult {
            type
            id
            name
            location {
              latitude
              longitude
            }
            isMatch
            isShared
            isWatched
            updatedAt
          }
          ... on MapCluster {
            name
            count
            location {
              latitude
              longitude
            }
            isMatch
            isShared
            isWatched
            expansionZoom
          }
        }
        pagination {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useMapSearchQuery__
 *
 * To run a query within a React component, call `useMapSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapSearchQuery({
 *   variables: {
 *      term: // value for 'term'
 *      xmin: // value for 'xmin'
 *      ymin: // value for 'ymin'
 *      xmax: // value for 'xmax'
 *      ymax: // value for 'ymax'
 *      zoom: // value for 'zoom'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      nights: // value for 'nights'
 *      people: // value for 'people'
 *      strict: // value for 'strict'
 *      firesAllowed: // value for 'firesAllowed'
 *      petsAllowed: // value for 'petsAllowed'
 *      accessible: // value for 'accessible'
 *      excludeGroup: // value for 'excludeGroup'
 *      onlyMatches: // value for 'onlyMatches'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      cluster: // value for 'cluster'
 *   },
 * });
 */
export function useMapSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<MapSearchQuery, MapSearchQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MapSearchQuery, MapSearchQueryVariables>(
    MapSearchDocument,
    options
  );
}
export function useMapSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MapSearchQuery,
    MapSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MapSearchQuery, MapSearchQueryVariables>(
    MapSearchDocument,
    options
  );
}
export function useMapSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MapSearchQuery,
    MapSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<MapSearchQuery, MapSearchQueryVariables>(
    MapSearchDocument,
    options
  );
}
export type MapSearchQueryHookResult = ReturnType<typeof useMapSearchQuery>;
export type MapSearchLazyQueryHookResult = ReturnType<
  typeof useMapSearchLazyQuery
>;
export type MapSearchSuspenseQueryHookResult = ReturnType<
  typeof useMapSearchSuspenseQuery
>;
export type MapSearchQueryResult = Apollo.QueryResult<
  MapSearchQuery,
  MapSearchQueryVariables
>;
export const PickerSearchDocument = gql`
  query PickerSearch($term: String) {
    search {
      search(
        input: {
          term: $term
          onlyMatches: false
          pagination: {page: 1, pageSize: 10}
          cluster: false
        }
      ) {
        results {
          ... on SearchResult {
            type
            id
            name
            result {
              ... on Facility {
                park {
                  name
                }
              }
              ... on Unit {
                facility {
                  name
                }
              }
            }
            isMatch
          }
        }
      }
    }
  }
`;

/**
 * __usePickerSearchQuery__
 *
 * To run a query within a React component, call `usePickerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickerSearchQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function usePickerSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PickerSearchQuery,
    PickerSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PickerSearchQuery, PickerSearchQueryVariables>(
    PickerSearchDocument,
    options
  );
}
export function usePickerSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickerSearchQuery,
    PickerSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PickerSearchQuery, PickerSearchQueryVariables>(
    PickerSearchDocument,
    options
  );
}
export function usePickerSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PickerSearchQuery,
    PickerSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<PickerSearchQuery, PickerSearchQueryVariables>(
    PickerSearchDocument,
    options
  );
}
export type PickerSearchQueryHookResult = ReturnType<
  typeof usePickerSearchQuery
>;
export type PickerSearchLazyQueryHookResult = ReturnType<
  typeof usePickerSearchLazyQuery
>;
export type PickerSearchSuspenseQueryHookResult = ReturnType<
  typeof usePickerSearchSuspenseQuery
>;
export type PickerSearchQueryResult = Apollo.QueryResult<
  PickerSearchQuery,
  PickerSearchQueryVariables
>;
export const GetOrganizationDetailsDocument = gql`
  query GetOrganizationDetails($id: Int!) {
    entities {
      organization(organizationId: $id) {
        ...OrganizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;

/**
 * __useGetOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  > &
    (
      | {variables: GetOrganizationDetailsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >(GetOrganizationDetailsDocument, options);
}
export function useGetOrganizationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >(GetOrganizationDetailsDocument, options);
}
export function useGetOrganizationDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >(GetOrganizationDetailsDocument, options);
}
export type GetOrganizationDetailsQueryHookResult = ReturnType<
  typeof useGetOrganizationDetailsQuery
>;
export type GetOrganizationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationDetailsLazyQuery
>;
export type GetOrganizationDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationDetailsSuspenseQuery
>;
export type GetOrganizationDetailsQueryResult = Apollo.QueryResult<
  GetOrganizationDetailsQuery,
  GetOrganizationDetailsQueryVariables
>;
export const GetParkDetailsDocument = gql`
  query GetParkDetails($id: Int!) {
    entities {
      park(parkId: $id) {
        ...ParkFields
      }
    }
  }
  ${ParkFieldsFragmentDoc}
`;

/**
 * __useGetParkDetailsQuery__
 *
 * To run a query within a React component, call `useGetParkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParkDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParkDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  > &
    (
      | {variables: GetParkDetailsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetParkDetailsQuery, GetParkDetailsQueryVariables>(
    GetParkDetailsDocument,
    options
  );
}
export function useGetParkDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetParkDetailsQuery, GetParkDetailsQueryVariables>(
    GetParkDetailsDocument,
    options
  );
}
export function useGetParkDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  >(GetParkDetailsDocument, options);
}
export type GetParkDetailsQueryHookResult = ReturnType<
  typeof useGetParkDetailsQuery
>;
export type GetParkDetailsLazyQueryHookResult = ReturnType<
  typeof useGetParkDetailsLazyQuery
>;
export type GetParkDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetParkDetailsSuspenseQuery
>;
export type GetParkDetailsQueryResult = Apollo.QueryResult<
  GetParkDetailsQuery,
  GetParkDetailsQueryVariables
>;
export const GetFacilityDetailsDocument = gql`
  query GetFacilityDetails($id: Int!) {
    entities {
      facility(facilityId: $id) {
        ...FacilityFields
        park {
          id
          name
        }
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
`;

/**
 * __useGetFacilityDetailsQuery__
 *
 * To run a query within a React component, call `useGetFacilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFacilityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  > &
    (
      | {variables: GetFacilityDetailsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >(GetFacilityDetailsDocument, options);
}
export function useGetFacilityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >(GetFacilityDetailsDocument, options);
}
export function useGetFacilityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >(GetFacilityDetailsDocument, options);
}
export type GetFacilityDetailsQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsQuery
>;
export type GetFacilityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsLazyQuery
>;
export type GetFacilityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsSuspenseQuery
>;
export type GetFacilityDetailsQueryResult = Apollo.QueryResult<
  GetFacilityDetailsQuery,
  GetFacilityDetailsQueryVariables
>;
export const GetFacilityDetailsWithSlotsDocument = gql`
  query GetFacilityDetailsWithSlots(
    $id: Int!
    $beginDate: Date!
    $endDate: Date!
  ) {
    entities {
      facility(facilityId: $id) {
        ...FacilityCommonFields
        park {
          id
          name
        }
        units {
          ...UnitFields
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
  ${FacilityCommonFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
`;

/**
 * __useGetFacilityDetailsWithSlotsQuery__
 *
 * To run a query within a React component, call `useGetFacilityDetailsWithSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityDetailsWithSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityDetailsWithSlotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetFacilityDetailsWithSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFacilityDetailsWithSlotsQuery,
    GetFacilityDetailsWithSlotsQueryVariables
  > &
    (
      | {variables: GetFacilityDetailsWithSlotsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFacilityDetailsWithSlotsQuery,
    GetFacilityDetailsWithSlotsQueryVariables
  >(GetFacilityDetailsWithSlotsDocument, options);
}
export function useGetFacilityDetailsWithSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilityDetailsWithSlotsQuery,
    GetFacilityDetailsWithSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFacilityDetailsWithSlotsQuery,
    GetFacilityDetailsWithSlotsQueryVariables
  >(GetFacilityDetailsWithSlotsDocument, options);
}
export function useGetFacilityDetailsWithSlotsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFacilityDetailsWithSlotsQuery,
    GetFacilityDetailsWithSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFacilityDetailsWithSlotsQuery,
    GetFacilityDetailsWithSlotsQueryVariables
  >(GetFacilityDetailsWithSlotsDocument, options);
}
export type GetFacilityDetailsWithSlotsQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsWithSlotsQuery
>;
export type GetFacilityDetailsWithSlotsLazyQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsWithSlotsLazyQuery
>;
export type GetFacilityDetailsWithSlotsSuspenseQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsWithSlotsSuspenseQuery
>;
export type GetFacilityDetailsWithSlotsQueryResult = Apollo.QueryResult<
  GetFacilityDetailsWithSlotsQuery,
  GetFacilityDetailsWithSlotsQueryVariables
>;
export const GetFacilityDetailsWithFilteredUnitsAndSlotsDocument = gql`
  query GetFacilityDetailsWithFilteredUnitsAndSlots(
    $id: Int!
    $term: String
    $xmin: Float
    $ymin: Float
    $xmax: Float
    $ymax: Float
    $beginDate: Date!
    $endDate: Date!
    $nights: Int
    $people: Int
    $strict: Boolean
    $firesAllowed: Boolean
    $petsAllowed: Boolean
    $accessible: Boolean
    $excludeFCFS: Boolean
    $excludeGroup: Boolean
    $excludeUnreleased: Boolean
    $onlyMatches: Boolean
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    entities {
      facility(facilityId: $id) {
        ...FacilityCommonFields
        park {
          id
          name
        }
        units(
          input: {
            term: $term
            boundingBox: {xmin: $xmin, ymin: $ymin, xmax: $xmax, ymax: $ymax}
            beginDate: $beginDate
            endDate: $endDate
            nights: $nights
            people: $people
            strict: $strict
            firesAllowed: $firesAllowed
            petsAllowed: $petsAllowed
            accessible: $accessible
            excludeFCFS: $excludeFCFS
            excludeGroup: $excludeGroup
            excludeUnreleased: $excludeUnreleased
            onlyMatches: $onlyMatches
            pagination: {page: $page, pageSize: $pageSize}
            sort: {field: $sortField, direction: $sortDirection}
          }
        ) {
          ...UnitFields
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
  ${FacilityCommonFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
`;

/**
 * __useGetFacilityDetailsWithFilteredUnitsAndSlotsQuery__
 *
 * To run a query within a React component, call `useGetFacilityDetailsWithFilteredUnitsAndSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityDetailsWithFilteredUnitsAndSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityDetailsWithFilteredUnitsAndSlotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      term: // value for 'term'
 *      xmin: // value for 'xmin'
 *      ymin: // value for 'ymin'
 *      xmax: // value for 'xmax'
 *      ymax: // value for 'ymax'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      nights: // value for 'nights'
 *      people: // value for 'people'
 *      strict: // value for 'strict'
 *      firesAllowed: // value for 'firesAllowed'
 *      petsAllowed: // value for 'petsAllowed'
 *      accessible: // value for 'accessible'
 *      excludeFCFS: // value for 'excludeFCFS'
 *      excludeGroup: // value for 'excludeGroup'
 *      excludeUnreleased: // value for 'excludeUnreleased'
 *      onlyMatches: // value for 'onlyMatches'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetFacilityDetailsWithFilteredUnitsAndSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  > &
    (
      | {
          variables: GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables;
          skip?: boolean;
        }
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  >(GetFacilityDetailsWithFilteredUnitsAndSlotsDocument, options);
}
export function useGetFacilityDetailsWithFilteredUnitsAndSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  >(GetFacilityDetailsWithFilteredUnitsAndSlotsDocument, options);
}
export function useGetFacilityDetailsWithFilteredUnitsAndSlotsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  >(GetFacilityDetailsWithFilteredUnitsAndSlotsDocument, options);
}
export type GetFacilityDetailsWithFilteredUnitsAndSlotsQueryHookResult =
  ReturnType<typeof useGetFacilityDetailsWithFilteredUnitsAndSlotsQuery>;
export type GetFacilityDetailsWithFilteredUnitsAndSlotsLazyQueryHookResult =
  ReturnType<typeof useGetFacilityDetailsWithFilteredUnitsAndSlotsLazyQuery>;
export type GetFacilityDetailsWithFilteredUnitsAndSlotsSuspenseQueryHookResult =
  ReturnType<
    typeof useGetFacilityDetailsWithFilteredUnitsAndSlotsSuspenseQuery
  >;
export type GetFacilityDetailsWithFilteredUnitsAndSlotsQueryResult =
  Apollo.QueryResult<
    GetFacilityDetailsWithFilteredUnitsAndSlotsQuery,
    GetFacilityDetailsWithFilteredUnitsAndSlotsQueryVariables
  >;
export const GetUnitDetailsDocument = gql`
  query GetUnitDetails($id: Int!) {
    entities {
      unit(unitId: $id) {
        ...UnitFields
        facility {
          id
          name
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;

/**
 * __useGetUnitDetailsQuery__
 *
 * To run a query within a React component, call `useGetUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  > &
    (
      | {variables: GetUnitDetailsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>(
    GetUnitDetailsDocument,
    options
  );
}
export function useGetUnitDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>(
    GetUnitDetailsDocument,
    options
  );
}
export function useGetUnitDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  >(GetUnitDetailsDocument, options);
}
export type GetUnitDetailsQueryHookResult = ReturnType<
  typeof useGetUnitDetailsQuery
>;
export type GetUnitDetailsLazyQueryHookResult = ReturnType<
  typeof useGetUnitDetailsLazyQuery
>;
export type GetUnitDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetUnitDetailsSuspenseQuery
>;
export type GetUnitDetailsQueryResult = Apollo.QueryResult<
  GetUnitDetailsQuery,
  GetUnitDetailsQueryVariables
>;
export const GetUnitDetailsWithSlotsDocument = gql`
  query GetUnitDetailsWithSlots($id: Int!, $beginDate: Date!, $endDate: Date!) {
    entities {
      unit(unitId: $id) {
        ...UnitFields
        slots(input: {beginDate: $beginDate, endDate: $endDate}) {
          ...SlotFields
        }
        facility {
          id
          name
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
`;

/**
 * __useGetUnitDetailsWithSlotsQuery__
 *
 * To run a query within a React component, call `useGetUnitDetailsWithSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitDetailsWithSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitDetailsWithSlotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetUnitDetailsWithSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  > &
    (
      | {variables: GetUnitDetailsWithSlotsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >(GetUnitDetailsWithSlotsDocument, options);
}
export function useGetUnitDetailsWithSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >(GetUnitDetailsWithSlotsDocument, options);
}
export function useGetUnitDetailsWithSlotsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >(GetUnitDetailsWithSlotsDocument, options);
}
export type GetUnitDetailsWithSlotsQueryHookResult = ReturnType<
  typeof useGetUnitDetailsWithSlotsQuery
>;
export type GetUnitDetailsWithSlotsLazyQueryHookResult = ReturnType<
  typeof useGetUnitDetailsWithSlotsLazyQuery
>;
export type GetUnitDetailsWithSlotsSuspenseQueryHookResult = ReturnType<
  typeof useGetUnitDetailsWithSlotsSuspenseQuery
>;
export type GetUnitDetailsWithSlotsQueryResult = Apollo.QueryResult<
  GetUnitDetailsWithSlotsQuery,
  GetUnitDetailsWithSlotsQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    users {
      currentUser {
        ...AuthenticatedUserFields
      }
    }
  }
  ${AuthenticatedUserFieldsFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetMaxDateDocument = gql`
  query GetMaxDate {
    search {
      maxDate
    }
  }
`;

/**
 * __useGetMaxDateQuery__
 *
 * To run a query within a React component, call `useGetMaxDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxDateQuery,
    GetMaxDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetMaxDateQuery, GetMaxDateQueryVariables>(
    GetMaxDateDocument,
    options
  );
}
export function useGetMaxDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxDateQuery,
    GetMaxDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetMaxDateQuery, GetMaxDateQueryVariables>(
    GetMaxDateDocument,
    options
  );
}
export function useGetMaxDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMaxDateQuery,
    GetMaxDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetMaxDateQuery, GetMaxDateQueryVariables>(
    GetMaxDateDocument,
    options
  );
}
export type GetMaxDateQueryHookResult = ReturnType<typeof useGetMaxDateQuery>;
export type GetMaxDateLazyQueryHookResult = ReturnType<
  typeof useGetMaxDateLazyQuery
>;
export type GetMaxDateSuspenseQueryHookResult = ReturnType<
  typeof useGetMaxDateSuspenseQuery
>;
export type GetMaxDateQueryResult = Apollo.QueryResult<
  GetMaxDateQuery,
  GetMaxDateQueryVariables
>;
export const GetLocationDocument = gql`
  query GetLocation {
    users {
      location {
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export function useGetLocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<
  typeof useGetLocationLazyQuery
>;
export type GetLocationSuspenseQueryHookResult = ReturnType<
  typeof useGetLocationSuspenseQuery
>;
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>;
export const GetFeatureFlagDocument = gql`
  query GetFeatureFlag($flag: String!) {
    features {
      isEnabled(flag: $flag)
    }
  }
`;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  > &
    (
      | {variables: GetFeatureFlagQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(
    GetFeatureFlagDocument,
    options
  );
}
export function useGetFeatureFlagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(
    GetFeatureFlagDocument,
    options
  );
}
export function useGetFeatureFlagSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  >(GetFeatureFlagDocument, options);
}
export type GetFeatureFlagQueryHookResult = ReturnType<
  typeof useGetFeatureFlagQuery
>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<
  typeof useGetFeatureFlagLazyQuery
>;
export type GetFeatureFlagSuspenseQueryHookResult = ReturnType<
  typeof useGetFeatureFlagSuspenseQuery
>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<
  GetFeatureFlagQuery,
  GetFeatureFlagQueryVariables
>;
export const GetCheckoutConfigDocument = gql`
  query GetCheckoutConfig {
    subscriptions {
      checkoutConfig {
        publishableKey
        plans {
          id
          name
          description
          amount
          currency
          interval
        }
      }
    }
  }
`;

/**
 * __useGetCheckoutConfigQuery__
 *
 * To run a query within a React component, call `useGetCheckoutConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCheckoutConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >(GetCheckoutConfigDocument, options);
}
export function useGetCheckoutConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >(GetCheckoutConfigDocument, options);
}
export function useGetCheckoutConfigSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >(GetCheckoutConfigDocument, options);
}
export type GetCheckoutConfigQueryHookResult = ReturnType<
  typeof useGetCheckoutConfigQuery
>;
export type GetCheckoutConfigLazyQueryHookResult = ReturnType<
  typeof useGetCheckoutConfigLazyQuery
>;
export type GetCheckoutConfigSuspenseQueryHookResult = ReturnType<
  typeof useGetCheckoutConfigSuspenseQuery
>;
export type GetCheckoutConfigQueryResult = Apollo.QueryResult<
  GetCheckoutConfigQuery,
  GetCheckoutConfigQueryVariables
>;
export const GetWatchListDocument = gql`
  query GetWatchList($status: WatchStatus) {
    watches {
      watchList(status: $status) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;

/**
 * __useGetWatchListQuery__
 *
 * To run a query within a React component, call `useGetWatchListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchListQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetWatchListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWatchListQuery,
    GetWatchListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetWatchListQuery, GetWatchListQueryVariables>(
    GetWatchListDocument,
    options
  );
}
export function useGetWatchListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchListQuery,
    GetWatchListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetWatchListQuery, GetWatchListQueryVariables>(
    GetWatchListDocument,
    options
  );
}
export function useGetWatchListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWatchListQuery,
    GetWatchListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetWatchListQuery, GetWatchListQueryVariables>(
    GetWatchListDocument,
    options
  );
}
export type GetWatchListQueryHookResult = ReturnType<
  typeof useGetWatchListQuery
>;
export type GetWatchListLazyQueryHookResult = ReturnType<
  typeof useGetWatchListLazyQuery
>;
export type GetWatchListSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchListSuspenseQuery
>;
export type GetWatchListQueryResult = Apollo.QueryResult<
  GetWatchListQuery,
  GetWatchListQueryVariables
>;
export const GetWatchDocument = gql`
  query GetWatch($id: Int!) {
    watches {
      watch(watchId: $id) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;

/**
 * __useGetWatchQuery__
 *
 * To run a query within a React component, call `useGetWatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetWatchQuery, GetWatchQueryVariables> &
    ({variables: GetWatchQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetWatchQuery, GetWatchQueryVariables>(
    GetWatchDocument,
    options
  );
}
export function useGetWatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchQuery,
    GetWatchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetWatchQuery, GetWatchQueryVariables>(
    GetWatchDocument,
    options
  );
}
export function useGetWatchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWatchQuery,
    GetWatchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetWatchQuery, GetWatchQueryVariables>(
    GetWatchDocument,
    options
  );
}
export type GetWatchQueryHookResult = ReturnType<typeof useGetWatchQuery>;
export type GetWatchLazyQueryHookResult = ReturnType<
  typeof useGetWatchLazyQuery
>;
export type GetWatchSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchSuspenseQuery
>;
export type GetWatchQueryResult = Apollo.QueryResult<
  GetWatchQuery,
  GetWatchQueryVariables
>;
export const GetWatchFoundDocument = gql`
  query GetWatchFound($id: Int!, $beginDate: Date!, $endDate: Date!) {
    watches {
      watch(watchId: $id) {
        id
        foundUnits {
          ...UnitFields
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
`;

/**
 * __useGetWatchFoundQuery__
 *
 * To run a query within a React component, call `useGetWatchFoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchFoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchFoundQuery({
 *   variables: {
 *      id: // value for 'id'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetWatchFoundQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWatchFoundQuery,
    GetWatchFoundQueryVariables
  > &
    ({variables: GetWatchFoundQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetWatchFoundQuery, GetWatchFoundQueryVariables>(
    GetWatchFoundDocument,
    options
  );
}
export function useGetWatchFoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchFoundQuery,
    GetWatchFoundQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetWatchFoundQuery, GetWatchFoundQueryVariables>(
    GetWatchFoundDocument,
    options
  );
}
export function useGetWatchFoundSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWatchFoundQuery,
    GetWatchFoundQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetWatchFoundQuery,
    GetWatchFoundQueryVariables
  >(GetWatchFoundDocument, options);
}
export type GetWatchFoundQueryHookResult = ReturnType<
  typeof useGetWatchFoundQuery
>;
export type GetWatchFoundLazyQueryHookResult = ReturnType<
  typeof useGetWatchFoundLazyQuery
>;
export type GetWatchFoundSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchFoundSuspenseQuery
>;
export type GetWatchFoundQueryResult = Apollo.QueryResult<
  GetWatchFoundQuery,
  GetWatchFoundQueryVariables
>;
export const SearchPlacesDocument = gql`
  query SearchPlaces(
    $term: String!
    $near: LocationInput
    $sessionToken: String
    $page: Int
    $pageSize: Int
  ) {
    places {
      search(
        input: {
          term: $term
          near: $near
          sessionToken: $sessionToken
          pagination: {page: $page, pageSize: $pageSize}
        }
      ) {
        results {
          ...PlaceFields
        }
        sessionToken
      }
    }
  }
  ${PlaceFieldsFragmentDoc}
`;

/**
 * __useSearchPlacesQuery__
 *
 * To run a query within a React component, call `useSearchPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlacesQuery({
 *   variables: {
 *      term: // value for 'term'
 *      near: // value for 'near'
 *      sessionToken: // value for 'sessionToken'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSearchPlacesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPlacesQuery,
    SearchPlacesQueryVariables
  > &
    ({variables: SearchPlacesQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<SearchPlacesQuery, SearchPlacesQueryVariables>(
    SearchPlacesDocument,
    options
  );
}
export function useSearchPlacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPlacesQuery,
    SearchPlacesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<SearchPlacesQuery, SearchPlacesQueryVariables>(
    SearchPlacesDocument,
    options
  );
}
export function useSearchPlacesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchPlacesQuery,
    SearchPlacesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<SearchPlacesQuery, SearchPlacesQueryVariables>(
    SearchPlacesDocument,
    options
  );
}
export type SearchPlacesQueryHookResult = ReturnType<
  typeof useSearchPlacesQuery
>;
export type SearchPlacesLazyQueryHookResult = ReturnType<
  typeof useSearchPlacesLazyQuery
>;
export type SearchPlacesSuspenseQueryHookResult = ReturnType<
  typeof useSearchPlacesSuspenseQuery
>;
export type SearchPlacesQueryResult = Apollo.QueryResult<
  SearchPlacesQuery,
  SearchPlacesQueryVariables
>;
export const GetPlaceLocationDocument = gql`
  query GetPlaceLocation($id: String!, $sessionToken: String) {
    places {
      location(placeId: $id, sessionToken: $sessionToken) {
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useGetPlaceLocationQuery__
 *
 * To run a query within a React component, call `useGetPlaceLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPlaceLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlaceLocationQuery,
    GetPlaceLocationQueryVariables
  > &
    (
      | {variables: GetPlaceLocationQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetPlaceLocationQuery, GetPlaceLocationQueryVariables>(
    GetPlaceLocationDocument,
    options
  );
}
export function useGetPlaceLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlaceLocationQuery,
    GetPlaceLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetPlaceLocationQuery,
    GetPlaceLocationQueryVariables
  >(GetPlaceLocationDocument, options);
}
export function useGetPlaceLocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPlaceLocationQuery,
    GetPlaceLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetPlaceLocationQuery,
    GetPlaceLocationQueryVariables
  >(GetPlaceLocationDocument, options);
}
export type GetPlaceLocationQueryHookResult = ReturnType<
  typeof useGetPlaceLocationQuery
>;
export type GetPlaceLocationLazyQueryHookResult = ReturnType<
  typeof useGetPlaceLocationLazyQuery
>;
export type GetPlaceLocationSuspenseQueryHookResult = ReturnType<
  typeof useGetPlaceLocationSuspenseQuery
>;
export type GetPlaceLocationQueryResult = Apollo.QueryResult<
  GetPlaceLocationQuery,
  GetPlaceLocationQueryVariables
>;
export const GetRecentlyCheckedFacilitiesDocument = gql`
  query GetRecentlyCheckedFacilities {
    entities {
      recentlyCheckedFacilities {
        id
        name
        park {
          id
          name
        }
        lastCheckedAt
      }
    }
  }
`;

/**
 * __useGetRecentlyCheckedFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetRecentlyCheckedFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentlyCheckedFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentlyCheckedFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentlyCheckedFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >(GetRecentlyCheckedFacilitiesDocument, options);
}
export function useGetRecentlyCheckedFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >(GetRecentlyCheckedFacilitiesDocument, options);
}
export function useGetRecentlyCheckedFacilitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >(GetRecentlyCheckedFacilitiesDocument, options);
}
export type GetRecentlyCheckedFacilitiesQueryHookResult = ReturnType<
  typeof useGetRecentlyCheckedFacilitiesQuery
>;
export type GetRecentlyCheckedFacilitiesLazyQueryHookResult = ReturnType<
  typeof useGetRecentlyCheckedFacilitiesLazyQuery
>;
export type GetRecentlyCheckedFacilitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentlyCheckedFacilitiesSuspenseQuery
>;
export type GetRecentlyCheckedFacilitiesQueryResult = Apollo.QueryResult<
  GetRecentlyCheckedFacilitiesQuery,
  GetRecentlyCheckedFacilitiesQueryVariables
>;
export const CalculateUsedCreditsDocument = gql`
  query CalculateUsedCredits(
    $parkIds: [Int!]!
    $facilityIds: [Int!]!
    $unitIds: [Int!]!
  ) {
    subscriptions {
      calculateUsedCredits(
        parkIds: $parkIds
        facilityIds: $facilityIds
        unitIds: $unitIds
      )
    }
  }
`;

/**
 * __useCalculateUsedCreditsQuery__
 *
 * To run a query within a React component, call `useCalculateUsedCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateUsedCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateUsedCreditsQuery({
 *   variables: {
 *      parkIds: // value for 'parkIds'
 *      facilityIds: // value for 'facilityIds'
 *      unitIds: // value for 'unitIds'
 *   },
 * });
 */
export function useCalculateUsedCreditsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  > &
    (
      | {variables: CalculateUsedCreditsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >(CalculateUsedCreditsDocument, options);
}
export function useCalculateUsedCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >(CalculateUsedCreditsDocument, options);
}
export function useCalculateUsedCreditsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >(CalculateUsedCreditsDocument, options);
}
export type CalculateUsedCreditsQueryHookResult = ReturnType<
  typeof useCalculateUsedCreditsQuery
>;
export type CalculateUsedCreditsLazyQueryHookResult = ReturnType<
  typeof useCalculateUsedCreditsLazyQuery
>;
export type CalculateUsedCreditsSuspenseQueryHookResult = ReturnType<
  typeof useCalculateUsedCreditsSuspenseQuery
>;
export type CalculateUsedCreditsQueryResult = Apollo.QueryResult<
  CalculateUsedCreditsQuery,
  CalculateUsedCreditsQueryVariables
>;
export const CountDocument = gql`
  subscription Count($target: Int) {
    count(target: $target)
  }
`;

/**
 * __useCountSubscription__
 *
 * To run a query within a React component, call `useCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSubscription({
 *   variables: {
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCountSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CountSubscription,
    CountSubscriptionVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSubscription<CountSubscription, CountSubscriptionVariables>(
    CountDocument,
    options
  );
}
export type CountSubscriptionHookResult = ReturnType<
  typeof useCountSubscription
>;
export type CountSubscriptionResult =
  Apollo.SubscriptionResult<CountSubscription>;
export const ChatMessagesDocument = gql`
  subscription ChatMessages {
    chatMessages {
      ...ChatMessageFields
    }
  }
  ${ChatMessageFieldsFragmentDoc}
`;

/**
 * __useChatMessagesSubscription__
 *
 * To run a query within a React component, call `useChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatMessagesSubscription,
    ChatMessagesSubscriptionVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSubscription<
    ChatMessagesSubscription,
    ChatMessagesSubscriptionVariables
  >(ChatMessagesDocument, options);
}
export type ChatMessagesSubscriptionHookResult = ReturnType<
  typeof useChatMessagesSubscription
>;
export type ChatMessagesSubscriptionResult =
  Apollo.SubscriptionResult<ChatMessagesSubscription>;
