import * as amplitude from "@amplitude/analytics-browser";
import {ApolloProvider} from "@apollo/client";
import {config as fontAwesomeConfig} from "@fortawesome/fontawesome-svg-core";
import {MantineProvider} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";
import {Notifications} from "@mantine/notifications";
import {NavigationProgress, nprogress} from "@mantine/nprogress";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import type {AppProps} from "next/app";
import App from "next/app";
import {useRouter} from "next/router";
import {useEffect} from "react";
import {CookiesProvider} from "react-cookie";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

import "../styles/globals.scss";

fontAwesomeConfig.autoAddCss = false;

import {
  AuthProvider,
  getAuthenticationState,
} from "../components/contexts/AuthContext";
import type {AuthenticationState} from "../components/contexts/AuthContext";
import {MenuProvider} from "../components/contexts/MenuContext";
import {PositionProvider} from "../components/contexts/PositionContext";
import Layout from "../components/Layout";
import AddWatchCredits from "../components/modals/AddWatchCredits";
import Chat from "../components/modals/Chat";
import Settings from "../components/modals/Settings";
import Wait from "../components/modals/Wait";
import config from "../config";
import client from "../graphql/client";
import {getLogger} from "../logging";
import {theme} from "../theme";
import {useLocalStorage, getParamValue} from "../utils";

const LOG = getLogger(__filename);

LOG.info(
  `Initializing ${process.env.PACKAGE_NAME} v${process.env.PACKAGE_VERSION} (${process.env.ENV})...`
);

if (
  typeof window !== "undefined" &&
  // @ts-ignore
  typeof window._DATADOG_SYNTHETICS_BROWSER === "undefined"
) {
  // Initialize Amplitude (but ignore Datadog Synthetics bot and ignore running it on SSR)
  amplitude.init(config.amplitude.apiKey, {
    defaultTracking: true,
  });
}

const MyApp = ({
  Component,
  pageProps,
  authentication,
}: AppProps & {authentication: AuthenticationState}) => {
  const [referral, setReferral] = useLocalStorage<string | null>(
    "referral",
    null
  );
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", _url => nprogress.start());
    router.events.on("routeChangeComplete", () => nprogress.complete());
    router.events.on("routeChangeError", () => nprogress.reset());
  }, [router]);

  // Referral tracking
  if (!referral && (router.query.utm_source || "").length > 0) {
    const referralCode = getParamValue(router.query.utm_source);
    if (referralCode) {
      setReferral(referralCode);
    }
  }

  return (
    <ApolloProvider client={client}>
      <MantineProvider theme={theme} defaultColorScheme="light">
        <CookiesProvider>
          <AuthProvider client={client} authentication={authentication}>
            <MenuProvider targetId="menu">
              <ModalsProvider
                modals={{
                  chat: Chat,
                  settings: Settings,
                  addWatchCredits: AddWatchCredits,
                  wait: Wait,
                }}>
                <PositionProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </PositionProvider>
              </ModalsProvider>
            </MenuProvider>
            <div id="menu"></div>
            <Notifications />
            <NavigationProgress />
          </AuthProvider>
        </CookiesProvider>
      </MantineProvider>
    </ApolloProvider>
  );
};

MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  const authentication = await getAuthenticationState(client, appContext.ctx);

  return {
    ...appProps,
    authentication,
  };
};

// MyApp.whyDidYouRender = true;

export default MyApp;
