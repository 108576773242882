import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";

export interface Props {
  targetId?: string;
  children: React.ReactNode;
}

export default function Portal({targetId = "portal", children}: Props) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  if (!mounted) {
    return null;
  }

  const document: Document | undefined = window?.document;
  if (!document) {
    return null;
  }

  const targetElement: Element | null = document.querySelector(`#${targetId}`);
  if (!targetElement) {
    return null;
  }

  return createPortal(children, targetElement);
}
