import {track, identify, Identify} from "@amplitude/analytics-browser";
import fetch from "cross-fetch";
import dayjs from "dayjs";
import React from "react";
import {useCookies} from "react-cookie";

import config from "../../config";
import {TOKEN_COOKIE_NAME} from "../../constants";
import {queryWithNextContext} from "../../graphql/client";
import * as queries from "../../graphql/queries";
import type {User, Permissions} from "../../graphql/types";
import {getLogger} from "../../logging";

const log = getLogger(__filename);

type StatusType = "SIGNED_IN" | "SIGNED_OUT" | "ERROR";

export interface AuthenticationState {
  status: StatusType;
  user: User | null;
}

export interface AuthContextState {
  authentication: AuthenticationState;

  /**
   * Sign in user
   *
   * @param email
   * @param password
   * @return Next authentication state
   */
  login(email: string, password: string): Promise<AuthenticationState>;

  /**
   * Sign out current user
   *
   * @return Next authentication state
   */
  logout(): Promise<AuthenticationState>;

  /**
   * Check if current user has an assigned permission
   * @param permission Permission to check
   * @return Whether or not the current user has the assigned permission
   */
  hasPermission(permission: Permissions): boolean;
}

const SIGNED_OUT_STATE: AuthenticationState = {
  status: "SIGNED_OUT",
  user: null,
};

const AuthContext = React.createContext<AuthContextState>({
  authentication: SIGNED_OUT_STATE,
  login: async (
    _email: string,
    _password: string
  ): Promise<AuthenticationState> => SIGNED_OUT_STATE,
  logout: async (): Promise<AuthenticationState> => SIGNED_OUT_STATE,
  hasPermission: (permission: Permissions) => false,
});

export interface AuthProviderProps {
  authentication?: AuthenticationState;
  client: any;
  children: React.ReactNode;
}

/**
 * Get the current authentication state
 *
 * @param client GraphQL client
 * @param context
 * @return Authentication state
 */
export const getAuthenticationState = async (
  client: any,
  context: any
): Promise<AuthenticationState> => {
  const {data: {users: {currentUser = undefined} = {}} = {}} =
    await queryWithNextContext(
      context,
      {
        query: queries.GET_CURRENT_USER,
      },
      client
    );

  if (currentUser) {
    return {
      status: "SIGNED_IN",
      user: currentUser,
    };
  } else {
    return SIGNED_OUT_STATE;
  }
};

export const AuthProvider = ({
  client,
  authentication,
  children,
}: AuthProviderProps) => {
  authentication ||= SIGNED_OUT_STATE;

  const [_cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_NAME]);
  const failMessage = "An unexpected error occurred while signing in";

  const login = async (
    email: string,
    password: string
  ): Promise<AuthenticationState> => {
    let data;

    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    const res = await fetch(`${config.apiUrl}/token`, {
      method: "POST",
      body: formData,
    });

    const success = res.status === 200;
    console.log(res, "success", success);
    track("Sign In", {
      success,
    });

    if (success) {
      data = await res.json();
    } else {
      let error;
      try {
        error = await res.json();
      } catch (e) {
        throw new Error(failMessage);
      }

      throw new Error(error.detail || failMessage);
    }

    if (data?.access_token) {
      log.info("Sign in successful");
      const expires = dayjs().add(1, "years").toDate();
      setCookie(TOKEN_COOKIE_NAME, data.access_token, {expires, path: "/"});
      await client.resetStore();

      const ident = new Identify();
      ident.set("userId", data.user_id);
      ident.set("email", email);
      ident.add("signInCount", 1);
      identify(ident);

      return {
        status: "SIGNED_IN",
        user: null, // data.login.user,
      };
    } else {
      throw new Error(failMessage);
    }
  };

  const logout = async (): Promise<AuthenticationState> => {
    track("Sign Out");

    removeCookie(TOKEN_COOKIE_NAME);
    await client.resetStore();

    return SIGNED_OUT_STATE;
  };

  const hasPermission = (permission: Permissions): boolean => {
    return (authentication.user?.permissions || []).includes(permission);
  };

  return (
    <AuthContext.Provider
      value={{authentication, login, logout, hasPermission}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextState => React.useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;
