import {Temporal, Intl, toTemporalInstant} from "@js-temporal/polyfill";
import {useState, Dispatch, SetStateAction} from "react";

import type {User, Slot, SearchResultType, Watch} from "./graphql/types";
import {getLogger} from "./logging";

const log = getLogger(__filename);

// @ts-ignore
Date.prototype.toTemporalInstant = toTemporalInstant;

/**
 * jinja2 batch function (taken from https://github.com/jonbretman/jinja-to-js/blob/master/jinja-to-js-runtime.js)
 *
 * @param arr
 * @param size
 * @param fillWith
 */
export function batch(arr: any, size: number, fillWith?: number): Array<any[]> {
  const batched = arr.reduce(function (result: any, value: any) {
    let curr: any = result[result.length - 1];
    if (!curr || curr.length === size) {
      result.push([]);
      curr = result[result.length - 1];
    }

    curr.push(value);
    return result;
  }, []);

  const last: any = batched[batched.length - 1];
  if (last && last.length < size && fillWith !== undefined) {
    for (let i = 0; i < size - last.length; i++) {
      last.push(fillWith);
    }
  }

  return batched;
}

export interface UserSettings {
  legacy_search: boolean;
  disable_email_alerts: boolean;
  unsubscribe: boolean;
}

export const DEFAULT_SETTINGS: UserSettings = {
  legacy_search: false,
  disable_email_alerts: false,
  unsubscribe: false,
};

export function getUserSettings(user?: User | null): UserSettings {
  if (!user || !user.settingsJson) {
    return DEFAULT_SETTINGS;
  }

  const settings = JSON.parse(user.settingsJson) as {[key: string]: any};
  return {...DEFAULT_SETTINGS, ...settings};
}

export function getParamValue(param: string | string[] | void): string {
  if (Array.isArray(param)) {
    // Get first value
    return param[0] || "";
  } else {
    return param || "";
  }
}

export function getIDParamValue(param: string | string[] | void): number {
  if (param === undefined || param === null) {
    throw new Error("id value is required");
  }

  const value = getParamValue(param);
  return parseInt(value, 10);
}

// Hook
// Source: https://usehooks.com/useLocalStorage/
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      log.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore: any =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      log.error(error);
    }
  };

  return [storedValue, setValue];
}

export function blanksAsNulls(values: any) {
  const nextValues: any = {};

  for (const k in values) {
    if (!values.hasOwnProperty(k)) {
      continue;
    }

    const currValue = values[k];
    if (currValue === null || currValue === undefined) {
      nextValues[k] = currValue;
    } else if (typeof currValue === "string" && currValue === "") {
      nextValues[k] = null;
    } else if (typeof currValue === "object") {
      nextValues[k] = blanksAsNulls(currValue);
    } else {
      nextValues[k] = currValue;
    }
  }

  return nextValues;
}

export const parseCookie = (str?: string | null): {[key: string]: string} =>
  (str || "")
    .split(";")
    .map((v: string) => v.split("="))
    .filter((pair: string[]) => pair.length === 2)
    .reduce((acc: {[key: string]: string}, pair: string[]) => {
      acc[decodeURIComponent(pair[0].trim())] = decodeURIComponent(
        pair[1].trim()
      );
      return acc;
    }, {});

/**
 * Get minimum and maximum value from a list of values
 *
 * @param values List of values to find min/max of (missing values will be ignored)
 * @return minimum, maximum
 */
export function minMax<T>(values: T[]): [T | null, T | null] {
  let minValue: T | null = null;
  let maxValue: T | null = null;

  for (const value of values) {
    // Ignore missing values
    if (value === null || value === undefined) {
      continue;
    }

    if (!minValue || minValue > value) {
      minValue = value;
    }

    if (!maxValue || maxValue < value) {
      maxValue = value;
    }
  }

  return [minValue, maxValue];
}

interface WatchIds {
  parkIds: number[];
  facilityIds: number[];
  unitIds: number[];
}

/**
 * @param searchResult Result to apply to IDs
 * @param watch Watch with IDs to apply function to
 * @param applyFunc Function to apply result to IDs
 * @return Updated IDs
 */
export function applySearchResultToWatchIds(
  searchResult: Partial<SearchResultType>,
  watch: Partial<Watch>,
  applyFunc: (
    searchResult: Partial<SearchResultType>,
    ids: number[] | string[]
  ) => void
): WatchIds {
  const parkIds: number[] = (watch.parks || []).map(
    (park: SearchResultType) => park.id as number
  );
  const facilityIds: number[] = (watch.facilities || []).map(
    (facility: SearchResultType) => facility.id as number
  );
  const unitIds: number[] = (watch.units || []).map(
    (unit: SearchResultType) => unit.id as number
  );

  if (searchResult.__typename && searchResult.id) {
    if (searchResult.__typename === "Park") {
      applyFunc(searchResult, parkIds);
    } else if (searchResult.__typename === "Facility") {
      applyFunc(searchResult, facilityIds);
    } else if (searchResult.__typename === "Unit") {
      applyFunc(searchResult, unitIds);
    } else {
      throw new Error("Unexpected target type");
    }
  }

  return {
    parkIds,
    facilityIds,
    unitIds,
  };
}

/**
 * @param searchResult
 * @param watch
 * @return Updated Ids
 */
export function addSearchResultToWatchIds(
  searchResult: Partial<SearchResultType>,
  watch: Partial<Watch>
): WatchIds {
  return applySearchResultToWatchIds(
    searchResult,
    watch,
    (result: Partial<SearchResultType>, ids: number[] | string[]) => {
      if (!result.id) {
        return;
      }

      // @ts-ignore
      if (result.id && ids.indexOf(result.id) === -1) {
        // @ts-ignore
        ids.push(result.id);
      }
    }
  );
}

/**
 * @param searchResult
 * @param watch
 * @return Updated IDs
 */
export function removeSearchResultFromWatchIds(
  searchResult: Partial<SearchResultType>,
  watch: Partial<Watch>
): WatchIds {
  return applySearchResultToWatchIds(
    searchResult,
    watch,
    (result: Partial<SearchResultType>, ids: number[] | string[]) => {
      if (!result.id) {
        return;
      }

      // @ts-ignore
      const index = ids.indexOf(result.id);
      if (index > -1) {
        ids.splice(index, 1);
      }
    }
  );
}

export function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export function redirect(url: string): void {
  if (typeof window === "undefined") {
    return;
  }

  window.location.href = url;
}
