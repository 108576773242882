import {gql} from "@apollo/client";

export const PAGINATION_FIELDS = gql`
  fragment PaginationFields on PaginationResults {
    page
    pageSize
    totalResults
  }
`;

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    id
    type
    street
    city
    state
    zip
    country
    version
    isActive
    updatedAt
  }
`;

export const CLOSURE_FIELDS = gql`
  fragment ClosureFields on Closure {
    id
    beginsAt
    endsAt
    reason
    version
    isActive
    updatedAt
  }
`;

export const EQUIPMENT_FIELDS = gql`
  fragment EquipmentFields on Equipment {
    id
    name
    description
    isActive
    updatedAt
  }
`;

export const MEDIA_FIELDS = gql`
  fragment MediaFields on Media {
    id
    type
    title
    subtitle
    description
    credits
    url
    width
    height
    version
    isActive
    updatedAt
  }
`;

export const MEDIA_MARKER_FIELDS = gql`
  fragment MediaMarkerFields on Media {
    id
    title
    url
    width
    height
  }
`;

export const FACILITY_MARKER_FIELDS = gql`
  ${MEDIA_MARKER_FIELDS}

  fragment FacilityMarkerFields on Facility {
    location {
      latitude
      longitude
    }
    id
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    lastCheckedAt
  }
`;

export const UNIT_MARKER_FIELDS = gql`
  ${MEDIA_MARKER_FIELDS}
  ${FACILITY_MARKER_FIELDS}

  fragment UnitMarkerFields on Unit {
    location {
      latitude
      longitude
    }
    id
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    facility {
      ...FacilityMarkerFields
    }
  }
`;

export const UNIT_FIELDS = gql`
  ${EQUIPMENT_FIELDS}
  ${CLOSURE_FIELDS}
  ${MEDIA_FIELDS}

  fragment UnitFields on Unit {
    id
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    maxVehicles
    maxVehicleLengthFeet
    favoritesCount
    isEquipmentMandatory
    facility {
      id
      name
      park {
        id
        name
      }
    }
    allowedEquipment {
      ...EquipmentFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    version
    isActive
    updatedAt
  }
`;

export const FACILITY_UNIT_FIELDS = gql`
  ${MEDIA_FIELDS}

  fragment FacilityUnitFields on Unit {
    id
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    maxVehicles
    maxVehicleLengthFeet
    isEquipmentMandatory
    primaryMedia {
      ...MediaFields
    }
  }
`;

export const FACILITY_COMMON_FIELDS = gql`
  ${ADDRESS_FIELDS}
  ${CLOSURE_FIELDS}
  ${MEDIA_FIELDS}

  fragment FacilityCommonFields on Facility {
    id
    type
    name
    url
    bookingUrl
    reservationUrl
    mapUrl
    checkinHour
    checkoutHour
    description
    descriptionHtml
    feeDescription
    rules
    directions
    stayLimit
    phone
    email
    isReservable
    isAdaAccessible
    location {
      latitude
      longitude
    }
    favoritesCount
    unitCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    isWatched
    isShared
    version
    isActive
    updatedAt
    lastCheckedAt
  }
`;

export const FACILITY_FIELDS = gql`
  ${FACILITY_UNIT_FIELDS}
  ${FACILITY_COMMON_FIELDS}

  fragment FacilityFields on Facility {
    ...FacilityCommonFields
    units {
      ...FacilityUnitFields
    }
  }
`;

export const FACILITY_WITHOUT_UNITS_FIELDS = gql`
  ${FACILITY_COMMON_FIELDS}

  fragment FacilityWithoutUnitsFields on Facility {
    ...FacilityCommonFields
    unitCount
  }
`;

export const ORGANIZATION_FIELDS = gql`
  ${ADDRESS_FIELDS}

  fragment OrganizationFields on Organization {
    id
    name
    url
    addresses {
      ...AddressFields
    }
    version
    isActive
    updatedAt
  }
`;

export const PARK_MARKER_FIELDS = gql`
  ${MEDIA_MARKER_FIELDS}

  fragment ParkMarkerFields on Park {
    location {
      latitude
      longitude
    }
    id
    name
    primaryMedia {
      ...MediaMarkerFields
    }
  }
`;

export const PARK_FIELDS = gql`
  ${ADDRESS_FIELDS}
  ${CLOSURE_FIELDS}
  ${MEDIA_FIELDS}
  ${FACILITY_WITHOUT_UNITS_FIELDS}

  fragment ParkFields on Park {
    id
    name
    description
    descriptionHtml
    feeDescription
    directions
    stayLimit
    phone
    email
    reservationUrl
    mapUrl
    isReservable
    location {
      latitude
      longitude
    }
    polygonGeoJson
    favoritesCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    facilities {
      ...FacilityWithoutUnitsFields
    }
    version
    isActive
    updatedAt
  }
`;

export const SLOT_FIELDS = gql`
  fragment SlotFields on Slot {
    id
    slotDate
    status
    version
    updatedAt
  }
`;

// Basic fields that everyone sees
export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    displayName
    avatarImageUrl
  }
`;

// Fields only accessible to own authenticated user
export const AUTHENTICATED_USER_FIELDS = gql`
  ${USER_FIELDS}

  fragment AuthenticatedUserFields on User {
    ...UserFields
    firstName
    lastName
    email
    settingsJson
    maxWatchCredits
    usedWatchCredits
    permissions
    version
    isActive
    updatedAt
  }
`;

// Fields only accessible to administrators
export const ADMIN_USER_FIELDS = gql`
  ${AUTHENTICATED_USER_FIELDS}

  fragment AdminUserFields on User {
    roles
  }
`;

export const CHAT_MESSAGE = gql`
  ${USER_FIELDS}

  fragment ChatMessageFields on ChatMessage {
    id
    sender {
      ...UserFields
    }
    recipient {
      ...UserFields
    }
    message
    sentAt
    deletedAt
  }
`;

export const WATCH = gql`
  fragment WatchFields on Watch {
    id
    name
    displayName
    creator {
      id
    }
    filter {
      people {
        min
        max
      }
      isGroup
      petsAllowed
      adaAccessible
      isEquipmentMandatory
      maxVehicleLengthFeet
      maxVehicles
    }
    arrival {
      min
      max
    }
    consecutiveDays
    scheduleDescription
    status
    expiresAt
    lastScannedAt
    alertsEnabled
    version
    createdAt
    updatedAt
    parks {
      id
      name
    }
    facilities {
      id
      name
      park {
        id
        name
      }
    }
    units {
      id
      name
      facility {
        id
        name
        park {
          id
          name
        }
      }
    }
    effectiveFacilities {
      id
      name
      park {
        id
        name
      }
    }
    foundFacilities {
      id
      name
      park {
        id
        name
      }
    }
  }
`;

export const PLACE = gql`
  fragment PlaceFields on Place {
    id
    name
    region
    placeType
  }
`;
