import {ColorSchemeScript} from "@mantine/core";
import Head from "next/head";
import React from "react";

import Header from "./Header";

interface Props {
  children: React.ReactNode;
}

function Layout({children}: Props) {
  return (
    <>
      <Head>
        <title>Camp Observer</title>

        <meta httpEquiv="content-type" content="text/html;charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <meta charSet="UTF-8" />
        <meta httpEquiv="Content-Language" content="en" />
        <meta name="google" content="notranslate" />

        <link rel="shortcut icon" href="/images/logo.png" />

        <ColorSchemeScript />
      </Head>

      <Header />

      <main className="content" id="content-pane">
        {children}
      </main>
    </>
  );
}

// Layout.whyDidYouRender = true;

export default Layout;
