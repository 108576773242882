import {useMutation} from "@apollo/client";
import type {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {
  faBell,
  faFileInvoice,
  faGear,
  faCreditCard,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Center,
  CloseButton,
  Container,
  NumberInput,
  Space,
  Tooltip,
} from "@mantine/core";
import {ContextModalProps, modals} from "@mantine/modals";
import {Formik, FormikErrors} from "formik";
import {isEmpty} from "lodash";
import React, {ReactNode, useState} from "react";

import classes from "./AddWatchCredits.module.scss";
import * as mutations from "../../graphql/mutations";
import {useAuth} from "../contexts/AuthContext";
import WatchCredits from "../WatchCredits";

interface AddWatchCreditsForm {
  credits: number;
}

export function show() {
  modals.openContextModal({
    modal: "addWatchCredits",
    title: "Add Watch Credits",
    size: "lg",
    innerProps: {},
  });
}

export default function AddWatchCredits({
  context,
  id,
  innerProps,
}: ContextModalProps) {
  const {authentication} = useAuth();
  const [lastError, setLastError] = useState<string | null>(null);
  /*
  const [addWatchCredits, _addWatchCreditsResult] = useMutation(
    mutations.UPDATE_USER_SETTINGS
  );
   */

  return (
    <Formik<AddWatchCreditsForm>
      initialValues={{
        credits: 0,
      }}
      validate={values => {
        const errors: FormikErrors<AddWatchCreditsForm> = {};

        if (!values.credits || values.credits < 1) {
          errors.credits = "Positive number required";
        }

        return errors;
      }}
      onSubmit={async (values, {setSubmitting}) => {
        setLastError(null);

        try {
          /*
          await updateUserSettings({
            variables: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              oldPassword: values.oldPassword,
              newPassword: values.password,
              partialSettingsJson: JSON.stringify(values.settings),
            },
          });
          */
          context.closeModal(id);
        } catch (e: any) {
          setLastError(e.message);
          setSubmitting(false);
        }
      }}>
      {({
        handleSubmit,
        dirty,
        values,
        errors,
        isValidating,
        isSubmitting,
        submitCount,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <WatchCredits adding plannedUsedCredits={-values.credits} />

            <Space h="lg" />

            {!!lastError && (
              <Alert
                variant="filled"
                color="red"
                icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
                {lastError}
              </Alert>
            )}

            <Tooltip
              withArrow
              label="Number of additional watch credits you want to purchase">
              <NumberInput
                name="credits"
                label="Credits to add"
                error={submitCount > 0 && errors.credits}
                min={1}
                required
                value={values.credits || ""}
                onChange={value => setFieldValue("credits", value)}
                leftSection={
                  <CloseButton
                    aria-label="Clear input"
                    onClick={() => setFieldValue("credits", "")}
                    style={{
                      display: !!values.credits ? undefined : "none",
                    }}
                  />
                }
              />
            </Tooltip>
          </Container>

          <Space h="lg" />

          <Center>
            <Button.Group>
              <Tooltip
                withArrow
                label={
                  isEmpty(errors) ? "Add credits" : "Please correct errors"
                }
                color={isEmpty(errors) ? undefined : "red"}>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={!dirty || isValidating || isSubmitting}
                  loading={isSubmitting}
                  leftSection={
                    !isSubmitting &&
                    !isEmpty(errors) &&
                    submitCount > 0 && (
                      <FontAwesomeIcon icon={faExclamationTriangle} beatFade />
                    )
                  }>
                  Save
                </Button>
              </Tooltip>
              <Button variant="default" onClick={() => context.closeModal(id)}>
                Cancel
              </Button>
            </Button.Group>
          </Center>
        </form>
      )}
    </Formik>
  );
}
