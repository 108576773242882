"use client";

import {
  createTheme,
  MantineColorsTuple,
  DefaultMantineColor,
} from "@mantine/core";

// Update with https://mantine.dev/colors-generator/
const green: MantineColorsTuple = [
  "#edfcf2",
  "#dbf7e3",
  "#b1efc5",
  "#85e8a4",
  "#61e187",
  "#4cdd76",
  "#40dc6c",
  "#33c25a",
  "#29ad4f",
  "#189541",
];

export const theme = createTheme({
  /* Put your mantine theme override here */
  primaryColor: "green",
  primaryShade: 8,
  colors: {
    green,
  },
});
